<template>
    <div class="bg-white rounded-5">
        <div class="d-flex justify-content-center align-items-center py-3">
            <div class="w-75 position-absolute" style="background: lightgray">
                <div class="position-absolute w-100" style="height: 3px; background: #e3e3e3"></div>
                <div class="position-absolute" style="height: 3px; background: green" v-bind:class="progress"></div>
            </div>
            <div class="d-flex justify-content-between align-items-center" style="width: 78%">
                <div class="d-flex align-items-center justify-content-center icon-div" v-bind:class="getStatusClass(1)">
                    <span class="material-symbols-outlined icon" v-if="current_step > 1"> check_small </span>
                    <span class="material-symbols-outlined icon current-icon" v-if="current_step === 1">
                        fiber_manual_record
                    </span>
                </div>
                <div class="d-flex align-items-center justify-content-center icon-div" v-bind:class="getStatusClass(2)">
                    <span class="material-symbols-outlined icon" v-if="current_step < 2"> </span>
                    <span class="material-symbols-outlined icon" v-if="current_step > 2"> check_small </span>
                    <span class="material-symbols-outlined icon current-icon" v-if="current_step === 2">
                        fiber_manual_record
                    </span>
                </div>
                <div class="d-flex align-items-center justify-content-center icon-div" v-bind:class="getStatusClass(3)">
                    <span class="material-symbols-outlined icon" v-if="current_step < 3"> </span>
                    <span class="material-symbols-outlined icon" v-if="current_step > 3"> check_small </span>
                    <span class="material-symbols-outlined icon current-icon" v-if="current_step === 3">
                        fiber_manual_record
                    </span>
                </div>
                <div class="d-flex align-items-center justify-content-center icon-div" v-bind:class="getStatusClass(4)">
                    <span class="material-symbols-outlined icon" v-if="current_step < 4"> </span>
                    <span class="material-symbols-outlined icon current-icon" v-if="current_step === 4">
                        fiber_manual_record
                    </span>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center w-100">
                <div class="w-25 d-flex justify-content-center">
                    <h6 style="color: #a0a0a0">Select Company & Application</h6>
                </div>
                <div class="w-25 d-flex justify-content-center">
                    <h6 style="color: #a0a0a0">Select Modules</h6>
                </div>
                <div class="w-25 d-flex justify-content-center">
                    <h6 style="color: #a0a0a0">Customize Theme</h6>
                </div>
                <div class="w-25 d-flex justify-content-center">
                    <h6 style="color: #a0a0a0">Build Dashboard</h6>
                </div>
            </div>
        </div>
        <div class="px-5 mx-4">
            <div class="d-flex justify-content-start">
                <SelectCompanyApplication
                    v-if="current_step === 1 && dataFetched == true"
                    @captureValues="captureValues"
                    :errors="page1Errors"
                    :pageData="pageData"
                    :healthCareProviderData="healthCareProvider"
                    :applicationFrame="applicationFrame"
                    :templateType="templateType"
                    :dashboardName="dashboardName"
                />
                <SelectModules
                    v-if="current_step === 2"
                    @captureValues="captureValues"
                    :errors="page2Errors"
                    :pageData="pageData"
                    :applicationFrame="applicationFrame"
                    :current_template="current_template"
                />
                <CustomisedThemeDesign
                    v-if="current_step === 3"
                    @captureValues="captureValues"
                    :errors="page3Errors"
                    :pageData="pageData"
                    :applicationFrame="applicationFrame"
                    :selected_language="selected_language"
                    :current_template="current_template"
                />
                <BuildDashboard
                    v-if="current_step === 4 && applicationFrame === 'mobex-health-hub' && current_template === 1"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <Build_MHH_FC_Dashboard
                    v-if="current_step === 4 && applicationFrame === 'mobex-health-hub' && current_template === 4"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <Build_MHH_Call_button_Dashboard
                    v-if="current_step === 4 && applicationFrame === 'mobex-health-hub' && current_template === 6"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <Build_MHH_3_Call_button_Dashboard
                    v-if="current_step === 4 && applicationFrame === 'mobex-health-hub' && current_template === 8"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <BuildKioskDashboard
                    v-if="current_step === 4 && this.applicationFrame == 'kiosk'"
                    :rssFeedsDetails="rssFeedsDetails"
                    @captureRssFeed="captureRssFeed"
                    @captureEducationFeed="captureEducationFeed"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <BuildFamilyConnectDashboard
                    v-if="current_step === 4 && this.applicationFrame == 'family-connect'"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <BuildStarKidDashboard
                    v-if="
                        current_step === 4 &&
                        (this.applicationFrame == 'star-kid' || this.applicationFrame == 'mobex-health-plus')
                    "
                    :rssFeedsDetails="rssFeedsDetails"
                    @captureRssFeed="captureRssFeed"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
            </div>
        </div>

        <div class="mx-5">
            <div class="py-5 d-flex justify-content-end">
                <button class="other-btn" @click="onClickBack" v-if="current_step > 1">Back to Previous</button>
                <button
                    v-if="[2, 3].includes(current_step) && pageData.status != 'completed'"
                    class="other-btn"
                    @click="saveDraft"
                >
                    Save as Draft
                </button>
                <button v-if="current_step == 4" class="other-btn" @click="openLauncherModal = true">Preview</button>
                <button v-if="current_step < max_step" class="save-btn" @click="onClickNext">Next</button>
                <button v-if="current_step == max_step" class="save-btn" @click="completeDashboard">Save</button>
            </div>
        </div>
        <div class="previewdashboard">
            <PreviewModal
                v-if="openLauncherModal && this.applicationFrame == 'mobex-health-hub' && current_template == 1"
                :open="openLauncherModal"
                @close="close"
                v-bind:pageData="pageData"
                :applicationFrame="applicationFrame"
            />
        </div>
        <div class="previewdashboard">
            <PreviewModalMHH2
                v-if="openLauncherModal && this.applicationFrame == 'mobex-health-hub' && current_template == 4"
                :open="openLauncherModal"
                @close="close"
                v-bind:pageData="pageData"
                :applicationFrame="applicationFrame"
            />
        </div>
        <div class="previewdashboard">
            <PreviewModalMHH_CallButton
                v-if="openLauncherModal && this.applicationFrame == 'mobex-health-hub' && current_template == 6"
                :open="openLauncherModal"
                @close="close"
                v-bind:pageData="pageData"
                :applicationFrame="applicationFrame"
            />
        </div>
        <div class="previewdashboard">
            <PreviewModalMHH_3CallButton
                v-if="openLauncherModal && this.applicationFrame == 'mobex-health-hub' && current_template == 8"
                :open="openLauncherModal"
                @close="close"
                v-bind:pageData="pageData"
                :applicationFrame="applicationFrame"
            />
        </div>
        <div class="previewdashboardkiosk">
            <PreviewModalKiosk
                v-if="openLauncherModal && this.applicationFrame == 'kiosk'"
                :open="openLauncherModal"
                @close="close"
                v-bind:pageData="pageData"
                :applicationFrame="applicationFrame"
            />
        </div>
        <div class="previewdashboardkiosk">
            <PreviewModalStarKid
                v-if="
                    openLauncherModal &&
                    (this.applicationFrame == 'star-kid' || this.applicationFrame == 'mobex-health-plus')
                "
                :open="openLauncherModal"
                @close="close"
                v-bind:pageData="pageData"
                :applicationFrame="applicationFrame"
            />
        </div>
        <div class="previewdashboard">
            <PreviewModalFC
                v-if="openLauncherModal && this.applicationFrame == 'family-connect'"
                :open="openLauncherModal"
                @close="close"
                v-bind:pageData="pageData"
                :applicationFrame="applicationFrame"
            />
        </div>
        <Loading v-if="loading" />
    </div>
</template>
<script>
    import SelectCompanyApplication from './select_company_application.vue';
    import SelectModules from './select_modules.vue';
    import CustomisedThemeDesign from './customised_theme_design.vue';
    import BuildDashboard from './build_dashboard.vue';
    import Build_MHH_FC_Dashboard from './build_Mhh_fc_dashboard.vue';
    import Build_MHH_Call_button_Dashboard from './build_Mhh_call_button_dashboard.vue';
    import Build_MHH_3_Call_button_Dashboard from './build_mhh_3_call_button_dashboard.vue';
    import BuildKioskDashboard from './build_kiosk_dashboard.vue';
    import BuildStarKidDashboard from './build_starkid_dashboard.vue';
    import BuildFamilyConnectDashboard from './build_familyconnect_dashboard.vue';
    import PreviewModal from './preview_modal.vue';
    import PreviewModalMHH2 from './preview_modal_mhh2.vue';
    import PreviewModalMHH_CallButton from './preview_modal_mhh_call_button.vue';
    import PreviewModalMHH_3CallButton from './preview_modal_mhh_3_call_button.vue';
    import PreviewModalKiosk from './preview_modal_kiosk.vue';
    import PreviewModalStarKid from './preview_modal_starkid.vue';
    import PreviewModalFC from './preview_modal_fc.vue';
    import Loading from '@/components/general/loading/loading.vue';
    import { dashboardBuilder, clients, memberProfile } from '@/util/apiRequests';
    import { dashboardBuildNotification } from '@/util/helpers/graphql/activityReminders.graphql.js';

    export default {
        name: 'DashboardBuilder',
        components: {
            SelectCompanyApplication,
            SelectModules,
            CustomisedThemeDesign,
            BuildDashboard,
            Build_MHH_FC_Dashboard,
            Build_MHH_Call_button_Dashboard,
            Build_MHH_3_Call_button_Dashboard,
            BuildKioskDashboard,
            BuildStarKidDashboard,
            PreviewModal,
            PreviewModalMHH2,
            PreviewModalMHH_CallButton,
            PreviewModalMHH_3CallButton,
            PreviewModalKiosk,
            PreviewModalStarKid,
            BuildFamilyConnectDashboard,
            PreviewModalFC,
            Loading,
        },
        props: {
            isNextButtonClicked: Boolean,
        },
        data() {
            return {
                current_step: 1,
                current_template: 1,
                max_step: 4,
                pageData: {
                    page1: null,
                    page2: null,
                    page3: null,
                    page4: null,
                    status: 'draft',
                },
                openLauncherModal: false,
                healthCareProvider: null,
                applicationFrame: null,
                educationFeedDetails: null,
                templateType: null,
                clientEmails: null,
                dashboardName: null,
                page1Errors: {
                    healthCareProvider: null,
                    applicationFrame: null,
                    templateType: null,
                    dashboardName: null,
                },
                page2Errors: {
                    modules: null,
                },
                page3Errors: {
                    logo: null,
                    qrLogo: null,
                    color: null,
                    background: null,
                    digitalSignage: null,
                    timeText: null,
                    timeoutText: null,
                    showScreenSaver: null,
                    sensitiveScreenTimeout: null,
                    sensitiveAlertTimeout: null,
                    splashScreenMessage: null,
                },
                dashboardId: null,
                widgetsList: [],
                dataFetched: false,
                loading: true,
                rssFeedsDetails: {},
                previousRssFeedsDetails: {},
                selected_language: 'English',
                companyLogoImages: [],
                backgroundImages: [],
                digitalSignageImages: [],
                waitingAnimationList: [],
                qrLogoImages: [],
            };
        },
        computed: {
            progress: function () {
                let _class = '';
                if (this.current_step === 1) {
                    _class = '';
                } else if (this.current_step === 2) {
                    _class = 'w-33';
                } else if (this.current_step === 3) {
                    _class = 'w-67';
                } else if (this.current_step === 4) {
                    _class = 'w-100';
                }
                return _class;
            },
        },
        methods: {
            async fetchDashboardData(id) {
                let template = await this.$api.get(dashboardBuilder.fetchDashboardData(id));
                let data;
                if (template.data?.templateMeta?.data) {
                    data = JSON.parse(template.data.templateMeta.data);
                }

                if (template.data?.templateMeta?.status) {
                    this.pageData.status = template.data.templateMeta.status;
                }

                if (template.data?.templateMeta?.health_care_provider) {
                    this.healthCareProvider = JSON.parse(template.data.templateMeta.health_care_provider);
                }
                if (template.data?.templateMeta?.selected_template) {
                    this.templateType = JSON.parse(template.data?.templateMeta?.selected_template);
                }
                if (template.data?.templateMeta?.dashboardName) {
                    this.dashboardName = template.data.templateMeta.dashboardName;
                }
                if (template.data?.templateMeta?.selected_language) {
                    this.selected_language = template.data?.templateMeta?.selected_language;
                }

                this.applicationFrame = template.data?.templateMeta?.application;

                this.current_template = +this.templateType?.id;

                this.pageData.page1 = {
                    healthCareProvider: this.healthCareProvider,
                    applicationFrame: this.applicationFrame,
                    templateType: this.templateType,
                    dashboardName: this.dashboardName,
                    current_template: this.current_template,
                };

                this.pageData.page2 = {
                    coreFunctions: data?.page2?.coreFunctions,
                    thirdPartyFunctions: data?.page2?.thirdPartyFunctions,
                    genericFunctions: data?.page2?.genericFunctions,
                    enableQR: data?.page2?.enableQR,
                    showQR: data?.page2?.showQR,
                    isMaster: template?.data?.templateMeta?.isMaster,
                    bidirectional_messaging: template?.data?.templateMeta?.bidirectional_messaging,
                    bidirectionalDisclaimer: data?.page2?.bidirectionalDisclaimer ?? '',
                };

                this.pageData.page3 = {
                    logo: data?.page3?.logo,
                    qrLogo: data?.page3?.qrLogo,
                    colorScheme: data?.page3?.colorScheme,
                    waitingAnimation: data?.page3?.waitingAnimation,
                    background: data?.page3?.background,
                    digitalSignage: data?.page3?.digitalSignage,
                    timeText: data?.page3?.timeText,
                    timeoutText: data?.page3?.timeoutText,
                    showScreenSaver: data?.page3?.showScreenSaver,
                    showCompanyLogoInQR: data?.page3?.showCompanyLogoInQR,
                    sensitiveScreenTimeout: template?.data?.templateMeta?.sensitiveScreenTimeout,
                    sensitiveAlertTimeout: template?.data?.templateMeta?.sensitiveAlertTimeout,
                    showSplashScreen: template?.data?.templateMeta?.showSplashScreen,
                    showDisplayName: data?.page3?.showDisplayName ?? 1,
                    splashScreenMessage: data?.page3?.splashScreenMessage ?? '',
                    showStaticQR: data?.page3?.showStaticQR ?? 0,
                };

                this.pageData.page4 = {
                    template: data?.page4?.template,
                    templateData: data?.page4?.templateData,
                    templateStyles: data?.page4?.templateStyles,
                };

                let rssinfo = JSON.parse(template.data.templateMeta?.rssinfo);
                this.rssFeedsDetails = rssinfo;
                this.previousRssFeedsDetails = rssinfo;

                return true;
            },
            async onClickNext() {
                if (this.current_step === 1) {
                    if (!this.healthCareProvider) {
                        this.page1Errors.healthCareProvider = 'Please select health care provider !';
                        return;
                    } else if (!this.applicationFrame) {
                        this.page1Errors.healthCareProvider = null;
                        this.page1Errors.applicationFrame = 'Please select deliver application !';
                        return;
                    } else if (!this.templateType) {
                        this.page1Errors.templateType = null;
                        this.page1Errors.templateType = 'Please select a dashboard !';
                        return;
                    } else if (!this.dashboardName) {
                        this.page1Errors.dashboardName = null;
                        this.page1Errors.dashboardName = 'Please enter dashboard Name';
                        return;
                    }
                    const endpoint = dashboardBuilder.checkDashboardName();
                    const res = await this.$api.post(endpoint, {
                        dashboardName: this.dashboardName,
                        id: this.dashboardId,
                    });
                    if (!res.data.success) {
                        this.page1Errors.dashboardName = null;
                        this.page1Errors.dashboardName = res.data.error;
                        return;
                    }
                    this.page1Errors.healthCareProvider = null;
                    this.page1Errors.applicationFrame = null;
                    this.page1Errors.templateType = null;
                    this.page1Errors.dashboardName = null;
                } else if (this.current_step === 2) {
                    this.page1Errors = {
                        healthCareProvider: null,
                        applicationFrame: null,
                        templateType: null,
                        dashboardName: null,
                    };
                    // if( !this.pageData.page2 || (this.pageData.page2?.coreFunctions?.length == 0 && this.pageData.page2?.thirdPartyFunctions?.length == 0)) {
                    //     this.page2Errors.modules = "Please select atleast one module !"
                    //     return;
                    // }

                    if (!this.pageData.page2) {
                        this.page2Errors.modules = 'Please select atleast one module !';
                        return;
                    } else if (
                        !this.pageData.page2 ||
                        (this.pageData.page2 &&
                            this.pageData.page2.coreFunctions &&
                            this.pageData.page2.coreFunctions.length === 0 &&
                            this.applicationFrame === 'mobex-health-hub')
                    ) {
                        this.page2Errors.modules = 'Please select atleast one module !';
                        return;
                    }
                } else if (this.current_step === 3) {
                    this.page2Errors = {
                        modules: null,
                    };

                    let err = 0;

                    if (!this.pageData.page3.logo) {
                        this.page3Errors = {
                            ...this.page3Errors,
                            logo: 'Please select/upload company logo !',
                        };
                        err++;
                    }

                    if (this.pageData.page3.showCompanyLogoInQR && !this.pageData.page3.qrLogo) {
                        this.page3Errors = {
                            ...this.page3Errors,
                            qrLogo: 'Please select/upload company logo for QR !',
                        };
                        err++;
                    }

                    if (!this.pageData.page3.waitingAnimation && this.applicationFrame === 'kiosk') {
                        this.page3Errors = {
                            ...this.page3Errors,
                            waitingAnimation: 'Please select/upload waiting animation !',
                        };
                        err++;
                    }

                    if (this.applicationFrame != 'family-connect') {
                        if (!this.pageData.page3.colorScheme) {
                            this.page3Errors = {
                                ...this.page3Errors,
                                color: 'Please select any colour scheme !',
                            };
                            err++;
                        }

                        if (
                            !this.pageData.page3.background &&
                            (this.applicationFrame == 'kiosk' ||
                                (this.applicationFrame == 'mobex-health-hub' && this.current_template == 1))
                        ) {
                            this.page3Errors = {
                                ...this.page3Errors,
                                background: 'Please select/upload background image !',
                            };
                            err++;
                        }
                    }

                    if (this.pageData.page3.showSplashScreen) {
                        if (this.pageData.page3.splashScreenMessage === '') {
                            this.page3Errors = {
                                ...this.page3Errors,
                                splashScreenMessage: 'Please enter a message !',
                            };
                            err++;
                        }
                    }

                    if (this.pageData.page3.showScreenSaver) {
                        if (!this.pageData.page3.digitalSignage || this.pageData.page3.digitalSignage.length == 0) {
                            this.page3Errors = {
                                ...this.page3Errors,
                                digitalSignage: 'Please select atleast one image !',
                            };
                            err++;
                        }
                        if (!this.pageData.page3.timeText) {
                            this.page3Errors = {
                                ...this.page3Errors,
                                timeText: 'This field is Required !',
                            };
                            err++;
                        }
                        if (!this.pageData.page3.timeoutText) {
                            this.page3Errors = {
                                ...this.page3Errors,
                                timeoutText: 'This field is Required !',
                            };
                            err++;
                        }

                        if (
                            this.pageData.page3.timeoutText &&
                            this.pageData.page3.sensitiveScreenTimeout &&
                            this.pageData.page3.sensitiveScreenTimeout > this.pageData.page3.timeoutText
                        ) {
                            this.page3Errors = {
                                ...this.page3Errors,
                                sensitiveScreenTimeout: 'Value should be less than screen saver timeout !',
                            };
                            err++;
                        }
                    }

                    if (
                        (this.applicationFrame === 'kiosk' ||
                            (this.applicationFrame === 'mobex-health-hub' && this.current_template == 1)) &&
                        !this.pageData.page3.sensitiveScreenTimeout
                    ) {
                        this.page3Errors = {
                            ...this.page3Errors,
                            sensitiveScreenTimeout: 'This field is Required !',
                        };
                        err++;
                    }

                    if (
                        (this.applicationFrame === 'kiosk' ||
                            (this.applicationFrame === 'mobex-health-hub' && this.current_template == 1)) &&
                        !this.pageData.page3.sensitiveAlertTimeout
                    ) {
                        this.page3Errors = {
                            ...this.page3Errors,
                            sensitiveAlertTimeout: 'This field is Required !',
                        };
                        err++;
                    }

                    if (
                        (this.applicationFrame === 'kiosk' ||
                            (this.applicationFrame === 'mobex-health-hub' && this.current_template == 1)) &&
                        this.pageData.page3.sensitiveAlertTimeout >= this.pageData.page3.sensitiveScreenTimeout
                    ) {
                        this.page3Errors = {
                            ...this.page3Errors,
                            sensitiveAlertTimeout: 'Value should be less than sensitive screen timeout !',
                        };
                        err++;
                    }

                    if (err) {
                        return;
                    }

                    if (!this.pageData.page3) {
                        if (this.applicationFrame == 'family-connect') {
                            this.page3Errors = {
                                logo: 'Please upload company logo !',
                            };
                        } else {
                            this.page3Errors = {
                                background: 'Please select background image !',
                                logo: 'Please upload company logo !',
                            };
                        }

                        return;
                    }
                    if (
                        !this.pageData.page3.background &&
                        (this.applicationFrame == 'kiosk' ||
                            (this.applicationFrame == 'mobex-health-hub' && this.current_template == 1))
                    ) {
                        Object.assign(this.page3Errors, { background: 'Please select background image !' });
                        return;
                    }
                    // if (!this.pageData.page3.colorScheme) {
                    //     Object.assign(this.page3Errors, { colorScheme: "Please select color scheme !" });
                    //     return;
                    // }
                    if (!this.pageData.page3.logo) {
                        Object.assign(this.page3Errors, { logo: 'Please upload company logo !' });
                        return;
                    }
                } else if (this.current_step === 4) {
                    this.page3Errors = {
                        logo: null,
                        qrLogo: null,
                        color: null,
                        background: null,
                        digitalSignage: null,
                        timeText: null,
                        timeoutText: null,
                        showScreenSaver: null,
                        sensitiveScreenTimeout: null,
                        sensitiveAlertTimeout: null,
                    };
                }

                this.current_step < this.max_step ? this.current_step++ : this.current_step;
            },
            onClickBack() {
                this.current_step > 1 ? this.current_step-- : this.current_step;
            },
            onClickFirst() {
                this.current_step = 1;
            },
            close() {
                this.openLauncherModal = false;
            },
            getStatusClass(section) {
                let _class = '';
                if (section === this.current_step) {
                    _class = 'current';
                } else if (section < this.current_step) {
                    _class = 'completed';
                } else if (section > this.current_step) {
                    _class = 'upcoming';
                }
                return _class;
            },
            async updateTemplate(status, data) {
                try {
                    let templateData,
                        template = 1;

                    if (this.applicationFrame == 'mobex-health-hub') {
                        template =
                            this.current_template == 1
                                ? 1
                                : this.current_template == 4
                                ? 2
                                : this.current_template == 6
                                ? 3
                                : 4;
                    }

                    if (this.templateType == null) {
                        templateData = {
                            health_care_provider: this.healthCareProvider,
                            application: this.applicationFrame,
                            template: template,
                            dashboardName: this.dashboardName,
                            data: data,
                            template_meta_id: 1,
                            status,
                            rssinfo: this.rssFeedsDetails ?? {},
                            selected_language: this.selected_language,
                            sensitiveScreenTimeout: data?.page3?.sensitiveScreenTimeout ?? 30,
                            sensitiveAlertTimeout: data?.page3?.sensitiveAlertTimeout ?? 15,
                            isMaster: data?.page2?.isMaster ?? 0,
                            bidirectional_messaging: data?.page2?.bidirectional_messaging ?? 0,
                            showSplashScreen: data?.page3?.showSplashScreen,
                        };
                    } else {
                        templateData = {
                            health_care_provider: this.healthCareProvider,
                            application: this.applicationFrame,
                            template: template,
                            selected_template: this.templateType,
                            dashboardName: this.dashboardName,
                            data: data,
                            template_meta_id: 1,
                            status,
                            rssinfo: this.rssFeedsDetails ?? {},
                            selected_language: this.selected_language,
                            sensitiveScreenTimeout: data?.page3?.sensitiveScreenTimeout ?? 30,
                            sensitiveAlertTimeout: data?.page3?.sensitiveAlertTimeout ?? 15,
                            isMaster: data?.page2?.isMaster ?? 0,
                            bidirectional_messaging: data?.page2?.bidirectional_messaging ?? 0,
                            showSplashScreen: data?.page3?.showSplashScreen,
                        };
                    }

                    const endpoint = dashboardBuilder.updateTemplate(this.$route.query.id);
                    const templateCreatedResponse = await this.$api.put(endpoint, templateData);
                    if (!templateCreatedResponse.data.success) {
                        throw templateCreatedResponse.data.error;
                    }

                    let temp = [];
                    let temp1 = [];

                    if (data?.page4?.template) {
                        Object.keys(data.page4.template).forEach((group) => {
                            if (group === 'LI') {
                                Object.keys(data.page4.template[group]).forEach((li) => {
                                    if (
                                        data.page4.template[group][li].elements.length &&
                                        data.page4.template[group][li].elements[0].id != null
                                    ) {
                                        if (data.page4.template[group][li].elements[0].function_name == 'Dashboard') {
                                            temp1.push(data.page4.template[group][li].elements[0].id);
                                        } else {
                                            temp.push(data.page4.template[group][li].elements[0].id);
                                        }
                                    }
                                });
                            }
                            if (group === 'GSI') {
                                Object.keys(data.page4.template[group]).forEach((gsi) => {
                                    if (
                                        data.page4.template[group][gsi].elements.length &&
                                        data.page4.template[group][gsi].elements[0].id != null
                                    ) {
                                        if (data.page4.template[group][gsi].elements[0].function_type == 'Dashboard') {
                                            temp1.push(data.page4.template[group][gsi].elements[0].id);
                                        } else {
                                            temp.push(data.page4.template[group][gsi].elements[0].id);
                                        }
                                    }
                                });
                            }
                        });
                    }

                    const endpoint1 = memberProfile.changeActiveStatus();
                    const res = await this.$api.post(endpoint1, {
                        ids: temp,
                        embeddedids: temp1,
                        isMaster: this.pageData?.page2?.isMaster,
                        dashboardId: this.dashboardId,
                    });
                    if (!res.data.success) {
                        throw res.data.error;
                    }

                    let ids = [];

                    this.companyLogoImages.forEach((item) => {
                        ids.push(item.id);
                    });

                    this.backgroundImages.forEach((item) => {
                        ids.push(item.id);
                    });

                    if (this.applicationFrame == 'kiosk') {
                        this.digitalSignageImages.forEach((item) => {
                            if (item.type == 'dashboard_builder_digital_signage_image') {
                                ids.push(item.id);
                            }
                        });
                        this.waitingAnimationList.forEach((item) => {
                            if (item.type == 'dashboard_builder_waiting_animation') {
                                ids.push(item.id);
                            }
                        });
                        this.qrLogoImages.forEach((item) => {
                            if (item.type == 'dashboard_builder_qr_logo') {
                                ids.push(item.id);
                            }
                        });
                    }

                    const endpoint2 = dashboardBuilder.changeActiveStatusofDigitalSignage();
                    const result = await this.$api.post(endpoint2, { ids });

                    if (!result.data.success) {
                        throw result.data.error;
                    }

                    this.$toasted.success('Template updated successfully');

                    if (this.applicationFrame != 'family-connect') {
                        await this.sendDashboardBuildNotification();
                    }

                    this.loading = false;

                    this.$router.push('/dashboard-builder');
                } catch (error) {
                    this.$toasted.error(error);
                    this.loading = false;
                }
            },
            getCurrentTemplate(option) {
                this.current_template = option;
            },
            async getDashboardRelatedClientsEmail() {
                try {
                    const endpoint = clients.getClientsEmail(this.$route.query.id);
                    const clientEmailsResponse = await this.$api.get(endpoint);

                    const {
                        data: { emails, message },
                    } = clientEmailsResponse;
                    if (emails.length != 0) {
                        this.clientEmails = emails;
                    } else {
                        throw new Error(message);
                    }

                    return true;
                } catch (err) {
                    this.$toasted.success(`This dashboard is not assigned to any member !`);
                }
            },
            async sendDashboardBuildNotification() {
                if (!this.clientEmails) {
                    await this.getDashboardRelatedClientsEmail();
                }
                if (this.clientEmails != null && this.clientEmails.length) {
                    try {
                        await dashboardBuildNotification(this.clientEmails, this.applicationFrame);
                    } catch (err) {
                        return this.$toasted.error('Failed to send notifications to members');
                    }

                    return true;
                } else {
                    return false;
                }
            },

            async captureValues(event) {
                const { page1, page2, page3, page4, pageData } = event;
                if (page1) {
                    const { healthCareProvider, applicationFrame, templateType, dashboardName } = page1;
                    this.healthCareProvider = healthCareProvider ? healthCareProvider : this.healthCareProvider;
                    this.applicationFrame = applicationFrame ? applicationFrame : this.applicationFrame;
                    this.templateType = templateType ? templateType : this.templateType;
                    this.dashboardName = dashboardName ? dashboardName : this.dashboardName;
                    this.current_template = +this.templateType?.id;
                    this.pageData.page1 = {
                        healthCareProvider: this.healthCareProvider,
                        applicationFrame: this.applicationFrame,
                        templateType: this.templateType,
                        dashboardName: this.dashboardName,
                        current_template: +this.templateType?.id,
                    };
                }
                if (page2) {
                    const {
                        coreFunctions,
                        thirdPartyFunctions,
                        genericFunctions,
                        enableQR,
                        showQR,
                        isMaster,
                        bidirectional_messaging,
                        bidirectionalDisclaimer,
                    } = page2;
                    this.pageData.page2 = {
                        coreFunctions: coreFunctions ?? this.pageData.page2?.coreFunctions,
                        thirdPartyFunctions: thirdPartyFunctions ?? this.pageData.page2?.thirdPartyFunctions,
                        genericFunctions: genericFunctions ?? this.pageData.page2?.genericFunctions,
                        enableQR: enableQR ?? this.pageData.page2?.enableQR,
                        showQR: showQR ?? this.pageData.page2?.showQR,
                        isMaster: isMaster ?? this.pageData.page2?.isMaster ?? 0,
                        bidirectional_messaging:
                            bidirectional_messaging !== undefined
                                ? bidirectional_messaging
                                : this.pageData?.page2?.bidirectional_messaging,
                        bidirectionalDisclaimer:
                            bidirectionalDisclaimer ?? this.pageData?.page2?.bidirectionalDisclaimer,
                    };
                }
                if (page3) {
                    const {
                        logo,
                        qrLogo,
                        colorScheme,
                        background,
                        waitingAnimation,
                        waitingAnimationList,
                        qrLogoImages,
                        companyLogoImages,
                        backgroundImages,
                        digitalSignage,
                        digitalSignageImages,
                        timeText,
                        timeoutText,
                        showSplashScreen,
                        showScreenSaver,
                        showCompanyLogoInQR,
                        selected_language,
                        sensitiveScreenTimeout,
                        sensitiveAlertTimeout,
                        showDisplayName,
                        splashScreenMessage,
                        showStaticQR,
                    } = page3;

                    if (this.applicationFrame == 'kiosk') {
                        this.pageData.page3 = {
                            logo: logo ?? this.pageData.page3?.logo,
                            qrLogo: qrLogo !== undefined ? qrLogo : this.pageData.page3?.qrLogo,
                            colorScheme: colorScheme ? colorScheme : this.pageData.page3?.colorScheme,
                            background: background ? background : this.pageData.page3?.background,
                            waitingAnimation: waitingAnimation ?? this.pageData.page3?.waitingAnimation,
                            showScreenSaver: showScreenSaver ?? this.pageData.page3?.showScreenSaver ?? 1,
                            showCompanyLogoInQR: showCompanyLogoInQR ?? this.pageData.page3?.showCompanyLogoInQR,
                            digitalSignage: digitalSignage ? digitalSignage : this.pageData.page3?.digitalSignage,
                            timeText: timeText ? timeText : this.pageData.page3?.timeText,
                            timeoutText: timeoutText ? timeoutText : this.pageData.page3?.timeoutText,
                            sensitiveScreenTimeout: sensitiveScreenTimeout
                                ? sensitiveScreenTimeout
                                : this.pageData.page3?.sensitiveScreenTimeout,
                            sensitiveAlertTimeout: sensitiveAlertTimeout
                                ? sensitiveAlertTimeout
                                : this.pageData.page3?.sensitiveAlertTimeout,
                            showSplashScreen: showSplashScreen ?? this.pageData.page3?.showSplashScreen ?? 0,
                            showDisplayName: showDisplayName ?? this.pageData.page3?.showDisplayName ?? 0,
                            splashScreenMessage: splashScreenMessage ?? this.pageData?.page3?.splashScreenMessage,
                            showStaticQR: showStaticQR ?? this.pageData?.page3?.showStaticQR,
                        };
                    } else {
                        this.pageData.page3 = {
                            logo: logo ? logo : this.pageData.page3?.logo,
                            colorScheme: colorScheme ? colorScheme : this.pageData.page3?.colorScheme,
                            background: background ? background : this.pageData.page3?.background,
                            waitingAnimation: waitingAnimation
                                ? waitingAnimation
                                : this.pageData.page3?.waitingAnimation,
                            sensitiveScreenTimeout: sensitiveScreenTimeout
                                ? sensitiveScreenTimeout
                                : this.pageData.page3?.sensitiveScreenTimeout,
                            sensitiveAlertTimeout: sensitiveAlertTimeout
                                ? sensitiveAlertTimeout
                                : this.pageData.page3?.sensitiveAlertTimeout,
                        };
                    }
                    if (companyLogoImages) {
                        this.companyLogoImages = companyLogoImages;
                    }
                    if (backgroundImages) {
                        this.backgroundImages = backgroundImages;
                    }
                    if (digitalSignageImages) {
                        this.digitalSignageImages = digitalSignageImages;
                    }
                    if (waitingAnimationList) {
                        this.waitingAnimationList = waitingAnimationList;
                    }
                    if (qrLogoImages) {
                        this.qrLogoImages = qrLogoImages;
                    }
                    if (selected_language) {
                        this.selected_language = selected_language;
                    }
                }
                if (page4) {
                    const { template, templateStyles, templateData } = page4;
                    this.pageData.page4 = {
                        template: template ? template : this.pageData.page4?.template,
                        templateStyles: templateStyles ? templateStyles : this.pageData.page4?.templateStyles,
                        templateData: templateData ? templateData : this.pageData.page4?.templateData,
                    };
                }
                if (pageData) {
                    const { template, templateStyles, templateData } = pageData;
                    this.pageData.page4 = {
                        template: template,
                        templateStyles: templateStyles,
                        templateData: templateData,
                    };
                }
                // await this.getDashboardRelatedClientsEmail();
                this.dataFetched = true;
            },
            async saveDraft() {
                const data = {
                    page2: this.pageData.page2,
                    page3: this.pageData.page3,
                };
                if (this.$route.query.id) {
                    this.updateTemplate('draft', data);
                } else {
                    this.createTemplate('draft', data);
                }
            },
            captureRssFeed(data) {
                this.rssFeedsDetails = { ...data };
            },
            captureEducationFeed(data) {
                this.educationFeedDetails = { ...data };
            },
            async completeDashboard() {
                const data = {
                    page2: this.pageData.page2,
                    page3: this.pageData.page3,
                    page4: this.pageData.page4,
                };
                let _coreFunctions;
                if (data.page2.coreFunctions) {
                    _coreFunctions = data.page2.coreFunctions.map((el) => {
                        const { id, function_name, function_type, active } = el;
                        return {
                            id,
                            function_name,
                            function_type,
                            active,
                        };
                    });
                    Object.assign(data.page2, { coreFunctions: _coreFunctions });
                }

                let _thirdPartyFunctions;
                if (data.page2.thirdPartyFunctions) {
                    _thirdPartyFunctions = data.page2.thirdPartyFunctions.map((el) => {
                        const { id, function_name, function_type, image, active } = el;
                        return {
                            id,
                            function_name,
                            function_type,
                            image,
                            active,
                        };
                    });
                    Object.assign(data.page2, { thirdPartyFunctions: _thirdPartyFunctions });
                }

                let _genericFunctions;
                if (data.page2.genericFunctions) {
                    _genericFunctions = data.page2.genericFunctions.map((el) => {
                        const { id, function_name, function_type, image, active } = el;
                        return {
                            id,
                            function_name,
                            function_type,
                            image,
                            active,
                        };
                    });
                    Object.assign(data.page2, { genericFunctions: _genericFunctions });
                }

                data.page2.isMaster = data?.page2?.isMaster;

                data.page2.bidirectional_messaging = data?.page2?.bidirectional_messaging;

                data.page3 = {
                    logo: data?.page3?.logo,
                    qrLogo: data?.page3?.qrLogo,
                    colorScheme: data?.page3?.colorScheme,
                    background: data?.page3?.background,
                    waitingAnimation: data?.page3?.waitingAnimation,
                    digitalSignage: data?.page3?.digitalSignage,
                    timeText: data?.page3?.timeText,
                    timeoutText: data?.page3?.timeoutText,
                    showScreenSaver: data?.page3?.showScreenSaver,
                    sensitiveScreenTimeout: data?.page3?.sensitiveScreenTimeout ?? 30,
                    sensitiveAlertTimeout: data?.page3?.sensitiveAlertTimeout ?? 15,
                    showSplashScreen: data?.page3?.showSplashScreen ?? 0,
                    showDisplayName: data?.page3?.showDisplayName ?? 0,
                    splashScreenMessage: data?.page3?.splashScreenMessage,
                    showCompanyLogoInQR: data?.page3?.showCompanyLogoInQR,
                    showStaticQR: data?.page3?.showStaticQR,
                };

                this.loading = true;

                if (this.applicationFrame === 'kiosk') {
                    const previousRssKeys = Object.keys(this.previousRssFeedsDetails);
                    const rssKeys = Object.keys(this.rssFeedsDetails);

                    if (previousRssKeys.length && !rssKeys.length) {
                        await this.deleteRSS();
                    } else if (JSON.stringify(this.previousRssFeedsDetails) !== JSON.stringify(this.rssFeedsDetails)) {
                        await this.updateRSS();
                    }
                }

                if (this.$route.query.id) {
                    this.updateTemplate('completed', data);
                } else {
                    this.createTemplate('completed', data);
                }
            },
            async createTemplate(status, data) {
                try {
                    let templateData,
                        template = 1;

                    if (this.applicationFrame == 'mobex-health-hub') {
                        template =
                            this.current_template == 1
                                ? 1
                                : this.current_template == 4
                                ? 2
                                : this.current_template == 6
                                ? 3
                                : 4;
                    }

                    if (this.templateType == null) {
                        templateData = {
                            health_care_provider: this.healthCareProvider,
                            application: this.applicationFrame,
                            template: template,
                            data: data,
                            template_meta_id: 1,
                            dashboardName: this.dashboardName,
                            status,
                            rssinfo: this.rssFeedsDetails ?? {},
                            selected_language: this.selected_language,
                            sensitiveScreenTimeout: this.pageData?.page3?.sensitiveScreenTimeout ?? 30,
                            sensitiveAlertTimeout: data?.page3?.sensitiveAlertTimeout ?? 15,
                            isMaster: this.pageData?.page2?.isMaster ?? 0,
                            bidirectional_messaging: data?.page2?.bidirectional_messaging ?? 0,
                            showSplashScreen: data?.page3?.showSplashScreen,
                        };
                    } else {
                        templateData = {
                            health_care_provider: this.healthCareProvider,
                            application: this.applicationFrame,
                            template: template,
                            data: data,
                            template_meta_id: 1,
                            selected_template: this.templateType,
                            dashboardName: this.dashboardName,
                            status,
                            rssinfo: this.rssFeedsDetails ?? {},
                            selected_language: this.selected_language,
                            sensitiveScreenTimeout: this.pageData?.page3?.sensitiveScreenTimeout ?? 30,
                            sensitiveAlertTimeout: data?.page3?.sensitiveAlertTimeout ?? 15,
                            isMaster: this.pageData?.page2?.isMaster ?? 0,
                            bidirectional_messaging: data?.page2?.bidirectional_messaging ?? 0,
                            showSplashScreen: data?.page3?.showSplashScreen,
                        };
                    }

                    const endpoint = dashboardBuilder.createTemplate();
                    const templateCreatedResponse = await this.$api.post(endpoint, templateData);
                    if (!templateCreatedResponse.data.success) {
                        throw templateCreatedResponse.data.error;
                    }

                    let temp = [];
                    let temp1 = [];

                    if (data?.page4?.template) {
                        Object.keys(data.page4?.template).forEach((group) => {
                            if (group === 'LI') {
                                Object.keys(data.page4.template[group]).forEach((li) => {
                                    if (
                                        data.page4.template[group][li].elements.length &&
                                        data.page4.template[group][li].elements[0].id != null
                                    ) {
                                        if (data.page4.template[group][li].elements[0].function_name == 'Dashboard') {
                                            temp1.push(data.page4.template[group][li].elements[0].id);
                                        } else {
                                            temp.push(data.page4.template[group][li].elements[0].id);
                                        }
                                    }
                                });
                            }
                            if (group === 'GSI') {
                                Object.keys(data.page4.template[group]).forEach((gsi) => {
                                    if (
                                        data.page4.template[group][gsi].elements.length &&
                                        data.page4.template[group][gsi].elements[0].id != null
                                    ) {
                                        if (data.page4.template[group][gsi].elements[0].function_name == 'Dashboard') {
                                            temp1.push(data.page4.template[group][gsi].elements[0].id);
                                        } else {
                                            temp.push(data.page4.template[group][gsi].elements[0].id);
                                        }
                                    }
                                });
                            }
                        });
                    }

                    const endpoint1 = memberProfile.changeActiveStatus();
                    const res = await this.$api.post(endpoint1, {
                        ids: temp,
                        embeddedids: temp1,
                        isMaster: this.pageData?.page2?.isMaster,
                        dashboardId: this.dashboardId,
                    });
                    if (!res.data.success) {
                        throw res.data.error;
                    }

                    let ids = [];

                    this.companyLogoImages.forEach((item) => {
                        ids.push(item.id);
                    });

                    this.backgroundImages.forEach((item) => {
                        ids.push(item.id);
                    });

                    if (this.applicationFrame == 'kiosk') {
                        this.digitalSignageImages.forEach((item) => {
                            if (item.type == 'dashboard_builder_digital_signage_image') {
                                ids.push(item.id);
                            }
                        });
                        this.waitingAnimationList.forEach((item) => {
                            if (item.type == 'dashboard_builder_waiting_animation') {
                                ids.push(item.id);
                            }
                        });
                        this.qrLogoImages.forEach((item) => {
                            if (item.type == 'dashboard_builder_qr_logo') {
                                ids.push(item.id);
                            }
                        });
                    }

                    const endpoint2 = dashboardBuilder.changeActiveStatusofDigitalSignage();
                    const result = await this.$api.post(endpoint2, { ids });

                    if (!result.data.success) {
                        throw result.data.error;
                    }

                    this.$toasted.success('Template saved successfully');

                    this.loading = false;

                    this.$router.push('/dashboard-builder');
                } catch (error) {
                    this.$toasted.error(error);
                    this.loading = false;
                }
            },
            async getLastTemplateId() {
                try {
                    const lastidendpoint = dashboardBuilder.getLastTemplateId();
                    const res = await this.$api.get(lastidendpoint);
                    if (res.data.success) {
                        this.dashboardId = Number(res.data.response.AUTO_INCREMENT);
                    }
                } catch (err) {
                    console.error(err);
                }
            },
            async deleteUnSaved() {
                const endpoint1 = memberProfile.deleteUnSaved();
                const res = await this.$api.put(endpoint1);
                if (!res.data.success) {
                    throw res.data.error;
                }

                const endpoint2 = dashboardBuilder.deleteActiveStatusofDigitalSignage();
                const res1 = await this.$api.put(endpoint2);
                if (!res1.data.success) {
                    throw res1.data.error;
                }
            },
            async updateRSS() {
                try {
                    const data = {
                        rss_id: this.rssFeedsDetails?.id ?? null,
                        title: this.rssFeedsDetails?.feedtitle ?? '',
                        url: this.rssFeedsDetails?.url ?? '',
                        dashboard_id: this.dashboardId,
                        language: 'en',
                        language_title: 'English',
                    };
                    const endpoint = dashboardBuilder.updateRSS();
                    await this.$api.post(endpoint, data);
                } catch (err) {
                    console.error('Failed to update RSS', err);
                }
            },
            async deleteRSS() {
                try {
                    const endpoint = dashboardBuilder.deleteRSS(this.dashboardId);
                    await this.$api.delete(endpoint);
                } catch (err) {
                    console.error('Failed to delete RSS', err);
                }
            },
        },
        async created() {
            window.scrollTo(0, 0);

            await this.deleteUnSaved();

            if (this.$route.query.id) {
                this.dashboardId = Number(this.$route.query.id);
                await this.fetchDashboardData(this.$route.query.id);
            } else {
                await this.getLastTemplateId();
            }

            this.dataFetched = true;
            this.loading = false;
        },
    };
</script>

<style>
    .icon-div {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        z-index: 99;
    }

    .icon {
        font-size: 35px;
        font-weight: 800;
        border-radius: 50%;
    }

    .completed {
        border: 5px solid #5657be;
        background: #5657be;
        color: white;
    }

    .current {
        border: 5px solid #5657be;
        background: white;
        color: #5657be;
        border-radius: 50%;
    }

    .current-icon {
        border-radius: 50%;
        background: #5657be;
        font-size: 20px;
    }

    .upcoming {
        border: 5px solid lightgray;
        background: white;
    }

    .other-btn {
        background: #fff;
        box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
        color: #5057c3;
        width: 200px;
        height: 50px;
        font-family: Roboto;
        font-size: 16px;
        border-radius: 5px;
        border: 1px solid 5057C3;
        font-weight: 500;
    }

    .save-btn {
        background: #5057c3;
        box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
        color: white;
        width: 150px;
        height: 50px;
        font-family: Roboto;
        font-size: 16px;
        border-radius: 5px;
    }
</style>
