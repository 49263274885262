<template>
    <NoButtonModal :open="open" @close="cancel" id="the_new_client_dropdown_modal">

        <div class="modal-list-third-party-app" v-if="showAppListing">
            
                <ejs-tooltip class="tooltipcontainer" content="Close" target="#target" cssClass="customtooltip">
                    <span id="target" class="material-symbols-outlined btn-popup-close-preview ms-2 rounded-circle"
                        role="button" @click="cancel">close</span>
                </ejs-tooltip>
           
            <div class="preview-body">
                <div class='droppable-template' v-if="pageData.page4.templateStyles">
                    <div :style="pageData.page4.templateStyles.primeContainer">
                        <div class="" :style="pageData.page4.templateStyles.bgImageContainer">
                            <img class="" :style="pageData.page4.templateStyles.bgImage"
                                :src="pageData.page4.templateStyles.bgImageUrl" width="100" height="100" />
                        </div>
                        <div class='t-m-c' :style="pageData.page4.templateStyles.templateMainContainer">
                            <div class='u-l-c' :style="pageData.page4.templateStyles.upperLayoutContainer">
                                <div v-for="(item, index) in Object.values(pageData.page4.template.LI)"
                                    :style="pageData.page4.templateStyles.upperLayoutContainerItemCommon + item.style"
                                    :key="item.name + ''">
                                    <div class="d-flex justify-content-between w-100 align-items-center px-2" v-if="index == 0 && applicationFrame!='kiosk'">
                                        <div style="font-size: 20px;font-weight: bold" class="d-flex w-33">Mobex Health Hub</div>
                                        <div class="d-grid w-33" >
                                            <h1 style="font-weight: bold;">11:59 AM</h1>
                                            <h3 style="font-weight: bold;">Wednesday, February 28, 2023</h3>
                                        </div>                                        
                                        <div class="d-flex w-33 d-flex justify-content-end" >
                                            <img src="@/assets/px/drag-and-drop/message.png" alt="" style="width: 40px; height: 40px;">
                                            <img src="@/assets/px/drag-and-drop/logout.png" alt="" style="width: 40px; height: 40px;">
                                        </div>                                        
                                    </div>
                                    <div v-if="index == 1 && applicationFrame!='kiosk'" class="d-flex w-100 justify-content-between align-items-center" style="padding: 0 10px 0 30px;">
                                        <img src="@/assets/px/drag-and-drop/widgets/welcome.png" alt="">
                                    </div>
                                    <draggable class="draggable-list" :list="item.elements" v-if="index > 1"
                                        :group="{ name: 'myGroup', pull: false, put: true }"
                                        @change="manipulateWidgets($event, 'LI', item.name, 'add')"
                                        :options="{ accept: false }">
                                        <div :class="element.class" v-for="element in item.elements" :key="element.name">
                                            <!-- {{ element.name }}  -->
                                            <img :src="element.image" alt="" style="height: 100%; width: 100%; border-radius: 0.5rem;">
                                            <!-- <button
                                                @click="manipulateWidgets($event, 'LI', item.name, 'remove', element)">Remove</button> -->
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <div class='g-s-c' :style="pageData.page4.templateStyles.gridSliderContainer">
                                <div v-for="(item) in Object.entries(pageData.page4.template.GSI)" :class="item[1].class"
                                    :key="item[1].name + ''"
                                    :style="pageData.page4.templateStyles.gridSliderContainerItemCommon  + item[1].style">
                                    <draggable class="draggable-list" :list="item[0].elements"
                                        :group="{ name: 'myGroup', pull: false, put: true }"
                                        @change="manipulateWidgets($event, 'GSI', item[1].name, 'add')">
                                        <div :class="element.class" v-for="element in item[1].elements" :key="element.name" style="position: relative; display: flex; justify-content: center; align-items: center; flex-direction: column; height: 100%;">
                                            <img
                                                v-if="element.function_type == 'generic'"
                                                :src="element.image"
                                                alt=""
                                                :class="pageData.page4.template['GSI'][item[0]].title ? 'w-50 h-50' : 'w-90'"
                                                style="border-radius: 0.5rem;"
                                            />
                                            <img
                                                v-else-if="element.function_type == '3rdParty'"
                                                :src="element.image"
                                                alt=""
                                                class='w-50'
                                                style="border-radius: 0.5rem;"
                                            />
                                            <img 
                                                v-else-if="element.function_type === 'core' && element.function_name === 'RSS FEED'" 
                                                :src="element.image"
                                                alt=""
                                                class='w-50'
                                                style="border-radius: 0.5rem;"
                                            />
                                            <img 
                                                v-else-if="element.function_type === 'core'" 
                                                :src="element.image"
                                                alt=""
                                                :style="{'max-width' : element.function_name == 'Photos' ? '45%' : '70%' }"
                                            />
                                            <img v-else :src="element.image" alt="" class="h-100 w-100" style="border-radius: 0.5rem;"/>
                                            <p v-if="pageData.page4.template['GSI'][item[0]].title" class="widget-title" style="border-radius: 0.5rem;">
                                                {{ pageData.page4.template['GSI'][item[0]].title }}
                                            </p>
                                            <p v-else-if="element.function_type === 'core' && element.function_name === 'RSS FEED'" class="widget-title">
                                                {{ element.function_name  }}
                                            </p>
                                            <p v-else-if="element.function_type == '3rdParty'" class="widget-title">
                                                {{ element.function_name  }}
                                            </p>
                                            <!-- <button
                                                @click="manipulateWidgets($event, 'GSI', item.name, 'remove', element)">Remove</button> -->
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <div :style='pageData.page4.templateStyles.footerContainer' class="">
                                <div :style="pageData.page4.templateStyles.footerWeatherInfo" class="">
                                    <div class="sun-icon"></div>
                                    <div class="weather-text">Sunny Miami Florida</div>
                                </div>
                                <div class="" :style="pageData.page4.templateStyles.footerTabletStats">
                                    <div class="bottom-tablet-stats-icons">Bluetooth</div>
                                    <div class="bottom-tablet-stats-icons">Wifi</div>
                                    <div class="bottom-tablet-stats-icons">Online</div>
                                    <div class="bottom-tablet-stats-icons">Battery</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- ### Custom Loader ### -->
        <div v-if="showLoader" class="d-flex flex-column justify-content-center align-items-center loader-main">
            <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
            <span class="ms-3">Loading ...</span>
        </div>
    </NoButtonModal>
</template>

<script>
import NoButtonModal from '@/components/general/modals/NoButtonModal';
import draggable from "vuedraggable";


export default {
    name: 'PreviewModal',
    components: { NoButtonModal, draggable },
    props: {
        open: {
            type: Boolean,
            required: true
        },
        selected: {
            type: String,

        },
        pageData: {
            type: Object,
            required: true
        },
        applicationFrame:{
            type:String,
            required:true
        }

    },
    data() {
        return {
            showAppListing: true,
            showLoader: false,
        };
    },
    methods: {
        cancel() {
            this.showAppListing = true;
            this.isEditScreen = false;

            this.$emit('close');
        },
    },
    created() {

    }
};
</script>
<style scoped>


.loader-main {
    min-height: 50vh;
}

.add-list-function-loader {
    height: 2em;
    width: 2em;
    font-weight: bolder;
}

.img-loader-main {
    min-height: 30px;
}

.img-loader {
    height: .9em;
    width: .9em;
    font-weight: 600;
}

.img-loader-text {
    font-size: .8em;
    font-weight: 600;
    color: var(--base-theme-color);
}

.widget-title {
    height: 20px;
    width: 80%;
    margin-top: 0.5rem;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    text-transform: capitalize;
}
</style>
