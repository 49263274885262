<template>
    <NoButtonModal :open="open" @close="cancel" id="the_new_client_dropdown_modal">

        <div class="modal-list-third-party-app" v-if="showAppListing">
            
                <ejs-tooltip class="tooltipcontainer" content="Close" target="#target" cssClass="customtooltip">
                    <span id="target" class="material-symbols-outlined btn-popup-close-preview ms-2 rounded-circle"
                        role="button" @click="cancel">close</span>
                </ejs-tooltip>
           
            <div class="preview-body">
                <div class='droppable-template' v-if="pageData.page4.templateStyles">
                    <div :style="pageData.page4.templateStyles.primeContainer">
                        <div class="" :style="pageData.page4.templateStyles.bgImageContainer">
                           
                        </div>
                        <div style="position: absolute; top: 15px; left: 25px; right: 25px;">
                            <div class="d-flex justify-content-between w-100 align-items-center px-2"
                                        >
                                        <div class="d-grid w-33">
                                            <h1 style="font-weight: lighter;">Good Morning , Alfonso</h1>
                                            <h3 style="font-weight: bold;">Wednesday, February 28, 2023</h3>
                                        </div>
                                        
                                       
                                        
                                    </div>
                        </div>
                        <div class='t-m-c' :style="pageData.page4.templateStyles.templateMainContainer">
                            <div class='u-l-c' :style="pageData.page4.templateStyles.upperLayoutContainer">
                                <div v-for="(item, index) in Object.values(pageData.page4.template.LI)"
                                    :class="(item.elements.length>0 ? 'removeColor':'')"
                                    :style="pageData.page4.templateStyles.upperLayoutContainerItemCommon + item.style"
                                    style="margin-top: 20%;"
                                    :key="item.name + ''">
                                    
                                    <draggable class="draggable-list" :list="item.elements" v-if="index >=0"
                                        :group="{ name: 'myGroup', pull: false, put: true }"
                                        @change="manipulateWidgets($event, 'LI', item.name, 'add')"
                                        :options="{ accept: false }">
                                        <div :class="element.class" v-for="element in item.elements" :key="element.name">
                                            <!-- {{ element.name }}  -->
                                            <img :src="element.image" alt="" style="height: 100%; width: 100%;">
                                            <!-- <button
                                                @click="manipulateWidgets($event, 'LI', item.name, 'remove', element)">Remove</button> -->
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                          
                            
                            <div :style='pageData.page4.templateStyles.footerContainer' class="d-flex justify-content-between w-100 align-items-center h-200 ">
                               <img src="@/assets/px/drag-and-drop/bottom_bar.png" alt="" style="height:3rem;" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- ### Custom Loader ### -->
        <div v-if="showLoader" class="d-flex flex-column justify-content-center align-items-center loader-main">
            <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
            <span class="ms-3">Loading ...</span>
        </div>
    </NoButtonModal>
</template>

<script>
import NoButtonModal from '@/components/general/modals/NoButtonModal';
import draggable from "vuedraggable";


export default {
    name: 'PreviewModalFC',
    components: { NoButtonModal, draggable },
    props: {
        open: {
            type: Boolean,
            required: true
        },
        selected: {
            type: String,

        },
        pageData: {
            type: Object,
            required: true
        },
        applicationFrame:{
            type:String,
            required:true
        }

    },
    data() {
        return {
            showAppListing: true,
            showLoader: false,
        };
    },
    methods: {
        cancel() {
            this.showAppListing = true;
            this.isEditScreen = false;

            this.$emit('close');
        },
    },
    created() {

    }
};
</script>
<style scoped>

.removeColor{
    background-color: transparent !important;
}
.loader-main {
    min-height: 50vh;
}

.add-list-function-loader {
    height: 2em;
    width: 2em;
    font-weight: bolder;
}

.img-loader-main {
    min-height: 30px;
}

.img-loader {
    height: .9em;
    width: .9em;
    font-weight: 600;
}

.img-loader-text {
    font-size: .8em;
    font-weight: 600;
    color: var(--base-theme-color);
}
</style>
