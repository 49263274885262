<template>
    <div class="rounded-5 w-100 my-5">
        <div class="d-flex justify-content-between">
            <div class="bg-white rounded-4 border mx-2 widget-list w-25">
                <h1
                    class="px-3 py-3 page-heading"
                    style="background: #f1f7ff; font-weight: bold; border-radius: 14px 14px 0 0; margin-bottom: 0"
                >
                    Selected Modules widgets
                </h1>
                <draggable
                    class="draggable-list d-grid widgets-list w-90 p-4"
                    style="
                        grid-template-columns: repeat(2, 1fr);
                        gap: 3%;
                        overflow: auto;
                        height: 550px;
                        align-items: center;
                    "
                    :list="widgetsList"
                    :group="{ name: 'myGroup', pull: true, put: false }"
                    @start="onDragStart"
                    @end="onDragEnd"
                >
                    <div
                        v-for="(element, index) in widgetsList"
                        :key="element.id"
                        :id="index"
                        style="margin-left: auto; margin-right: auto"
                    >
                        <div v-if="element.function_type === 'generic'" class="generic-widget">
                            <img :src="element.image" alt="" style="margin: auto" />
                        </div>
                        <div
                            v-else-if="
                                element.function_type === 'core' && element.height === 'c' && element.width === 'c'
                            "
                            class="core-widget"
                            :style="{ background: element.function_name == 'Games2' ? '#4CBC9A' : 'white' }"
                        >
                            <img :src="element.image" alt="" />
                        </div>
                        <div
                            v-else-if="element.function_name == 'Web Links2'"
                            style="background-color: rgb(81, 85, 195)"
                            class="core-class"
                        >
                            <img :src="element.image" alt="" />
                        </div>
                        <div v-else class="core-class">
                            <img :src="element.image" alt="" />
                        </div>
                    </div>
                </draggable>
            </div>
            <div class="bg-white rounded-4 border mx-2 dash-builder w-75" style="min-width: 800px">
                <div
                    class="d-flex justify-content-between align-items-center"
                    style="background: #f1f7ff; border-radius: 14px 14px 0 0"
                >
                    <h1 class="px-5 py-3 page-heading" style="font-weight: bold">
                        Dashboard Builder {{ selectedOption }}
                    </h1>
                </div>
                <div class="droppable-template" v-if="templateStyles">
                    <div :style="templateStyles.primeContainer" class="w-100">
                        <div :style="templateStyles.bgImageContainer">
                            <img
                                :style="templateStyles.bgImage"
                                src="@/assets/px/drag-and-drop/MHH-FC-Background.png"
                                alt=""
                                width="100"
                                height="100"
                            />
                        </div>
                        <div :style="templateStyles.primeContainer" class="w-100">
                            <div class="px-5 py-4">
                                <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex w-70" style="gap: 1rem; align-items: center">
                                        <img
                                            src="@/assets/photos/MHH-user.png"
                                            alt=""
                                            style="
                                                width: 80px;
                                                height: 80px;
                                                border-radius: 50%;
                                                border: 2px solid #5155c3;
                                                padding: 0.2rem;
                                            "
                                        />
                                        <div>
                                            <h1 style="font-weight: 700; color: #5155c3">
                                                Hello, <span style="color: #4cbc9a">Jaron William</span>
                                            </h1>
                                            <h3 style="color: #271e4a">Tuesday, November 28, 2023, 10:23 AM</h3>
                                        </div>
                                    </div>
                                    <div class="w-30 d-flex" style="flex-direction: row-reverse; gap: 2rem">
                                        <div class="d-flex icon-container">
                                            <img
                                                src="@/assets/px/header-icons-images/logout.svg"
                                                alt=""
                                                width="30"
                                                height="30"
                                            />
                                            <span>Logout</span>
                                        </div>
                                        <div class="d-flex icon-container">
                                            <img
                                                src="@/assets/px/header-icons-images/notification.svg"
                                                alt=""
                                                width="30"
                                                height="30"
                                            />
                                            <span>Notifications</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="t-m-c w-100" :style="templateStyles.templateMainContainer">
                                <div class="u-l-c w-100 d-flex h-100" style="gap: 10px; margin-bottom: 50px">
                                    <div
                                        class="w-70 px-2"
                                        :style="templateStyles.upperLayoutContainer"
                                        style="
                                            align-items: center;
                                            place-items: center;
                                            display: grid;
                                            grid-template-columns: repeat(3, 1fr);
                                            grid-template-rows: repeat(2, 1fr);
                                        "
                                    >
                                        <div
                                            v-for="(item, index) in Object.entries(template.LI)"
                                            :class="
                                                item[1].width == width && item[1].height == height
                                                    ? ' highlighted-section ' + item[1].class
                                                    : '' + item[1].class
                                            "
                                            :key="item[1].name + ''"
                                            :style="{
                                                'grid-area': index == 2 ? '1/3/3' : '',
                                                'margin-left': index == 2 ? '1.3rem' : '',
                                                'border-radius': '35px',
                                                'background-color':
                                                    template['LI'][item[0]].elements.length &&
                                                    (index == 0 || index == 2)
                                                        ? 'transparent'
                                                        : 'white',
                                                'box-shadow':
                                                    pageData.page4.template['LI'][item[0]].elements.length &&
                                                    (index == 0 || index == 2)
                                                        ? ''
                                                        : '5px 10px 5px 5px rgba(0, 0, 0, 0.07), 0px 6px 4px 0px rgba(255, 255, 255, 0.07) inset',
                                                height: index == 2 ? '82%' : '75%',
                                                width: index == 2 ? '100%' : '12rem',
                                            }"
                                        >
                                            <draggable
                                                class="draggable-list"
                                                :list="template['LI'][item[0]].elements"
                                                :group="
                                                    template['LI'][item[0]].group
                                                        ? template['LI'][item[0]].group
                                                        : { name: 'myGroup', pull: false, put: true }
                                                "
                                                @change="manipulateWidgets($event, 'LI', item[1].name, 'add')"
                                            >
                                                <div
                                                    :class="value.class"
                                                    v-for="value in template['LI'][item[0]].elements"
                                                    :key="value.name"
                                                    @mouseover="hoveredElementId = 'LI' + index"
                                                    @mouseleave="hoveredElementId = null"
                                                    @click="
                                                        value.function_type === 'generic'
                                                            ? addDetails(value.function_name, index)
                                                            : ''
                                                    "
                                                    class="li-items-container"
                                                    :style="{
                                                        'background-color':
                                                            value.function_name == 'Games2'
                                                                ? '#4CBC9A'
                                                                : index == 0 || index == 2
                                                                ? 'transparent'
                                                                : 'white',
                                                    }"
                                                >
                                                    <img
                                                        v-if="value.function_type == 'generic'"
                                                        :src="value.image"
                                                        alt=""
                                                        :class="template['LI'][item[0]].title ? 'w-50 h-50' : 'w-90'"
                                                        style="border-radius: 0.5rem"
                                                    />
                                                    <img
                                                        v-else-if="value.function_type == '3rdParty'"
                                                        :src="value.image"
                                                        alt=""
                                                        class="w-50 h-50"
                                                        style="border-radius: 0.5rem"
                                                    />
                                                    <img
                                                        v-else-if="value.function_name == 'Scheduling2'"
                                                        :src="value.image"
                                                        alt=""
                                                        style="max-width: 13rem; border-radius: 0.5rem"
                                                    />
                                                    <img
                                                        v-else-if="value.function_name == 'Medication2'"
                                                        :src="value.image"
                                                        alt=""
                                                        style="height: 100%; width: 100%; border-radius: 0.5rem"
                                                    />
                                                    <img
                                                        v-else
                                                        :src="value.image"
                                                        alt=""
                                                        :style="{
                                                            width: value.function_name == 'Care Team2' ? '50%' : '70%',
                                                            'border-radius': '0.5rem',
                                                        }"
                                                    />
                                                    <p
                                                        v-if="
                                                            value.function_type == 'generic' &&
                                                            template['LI'][item[0]].title
                                                        "
                                                        class="widget-title"
                                                    >
                                                        {{ template['LI'][item[0]].title }}
                                                    </p>
                                                    <p
                                                        v-else-if="value.function_type == '3rdParty'"
                                                        class="widget-title"
                                                    >
                                                        {{ value.function_name }}
                                                    </p>
                                                    <template v-if="hoveredElementId == 'LI' + index">
                                                        <div
                                                            class="action-buttons-mhh w-100 h-100"
                                                            style="border-radius: 35px"
                                                        >
                                                            <span
                                                                class="background"
                                                                @click="
                                                                    manipulateWidgets(
                                                                        $event,
                                                                        'LI',
                                                                        item[1].name,
                                                                        'remove',
                                                                        value,
                                                                        index
                                                                    )
                                                                "
                                                                id="delete"
                                                            >
                                                                <img
                                                                    src="@/assets/px/delete-icon.png"
                                                                    alt=""
                                                                    style="height: 15px"
                                                                />
                                                            </span>
                                                            <div v-if="value.function_type === 'generic'">
                                                                <span
                                                                    class="background"
                                                                    @click="OpenEditModal(value.function_name, index)"
                                                                    id="edit"
                                                                >
                                                                    <img
                                                                        src="@/assets/px/edit-icon.png"
                                                                        alt=""
                                                                        style="height: 15px"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </draggable>
                                        </div>
                                    </div>

                                    <div class="w-20 p-2 side-container">
                                        <div
                                            v-for="(item, index) in Object.entries(template.GSI)"
                                            :class="
                                                item[1].width == width && item[1].height == height
                                                    ? ' highlighted-section side-container-item ' + item[1].class
                                                    : 'side-container-item ' + item[1].class
                                            "
                                            :key="item[1].name + ''"
                                        >
                                            <draggable
                                                class="draggable-list"
                                                :list="template['GSI'][item[0]].elements"
                                                :group="
                                                    template['GSI'][item[0]].group
                                                        ? template['GSI'][item[0]].group
                                                        : { name: 'myGroup', pull: false, put: true }
                                                "
                                                @change="manipulateWidgets($event, 'GSI', item[1].name, 'add')"
                                            >
                                                <div
                                                    :class="value.class"
                                                    v-for="value in template['GSI'][item[0]].elements"
                                                    :key="value.name"
                                                    @mouseover="hoveredElementId = 'GSI' + index"
                                                    @mouseleave="hoveredElementId = null"
                                                    @click="
                                                        value.function_type === 'generic'
                                                            ? addDetails(value.function_name, index)
                                                            : ''
                                                    "
                                                    :style="{
                                                        'background-color':
                                                            value.function_name == 'Web Links2' ? '#5155C3' : 'white',
                                                        'border-radius': '35px',
                                                        position: 'relative',
                                                        display: 'flex',
                                                        'justify-content': 'center',
                                                        'align-items': 'center',
                                                        'flex-direction': 'column',
                                                        height: '100%',
                                                    }"
                                                >
                                                    <img :src="value.image" alt="" style="max-width: 70%" />
                                                    <template v-if="hoveredElementId == 'GSI' + index">
                                                        <div
                                                            class="action-buttons-mhh w-100 h-100"
                                                            style="border-radius: 35px"
                                                        >
                                                            <span
                                                                class="background"
                                                                @click="
                                                                    manipulateWidgets(
                                                                        $event,
                                                                        'GSI',
                                                                        item[1].name,
                                                                        'remove',
                                                                        value,
                                                                        index
                                                                    )
                                                                "
                                                                id="delete"
                                                            >
                                                                <img
                                                                    src="@/assets/px/delete-icon.png"
                                                                    alt=""
                                                                    style="height: 15px"
                                                                />
                                                            </span>
                                                            <div v-if="value.function_type === 'generic'">
                                                                <span
                                                                    class="background"
                                                                    @click="OpenEditModal(value.function_name, index)"
                                                                    id="edit"
                                                                >
                                                                    <img
                                                                        src="@/assets/px/edit-icon.png"
                                                                        alt=""
                                                                        style="height: 15px"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </draggable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="d-flex w-100 align-items-center"
                                style="position: absolute; bottom: 0; left: 0; border-radius: 10px 20px"
                            >
                                <img
                                    src="@/assets/px/drag-and-drop/footer.png"
                                    alt=""
                                    style="max-height: 4rem; border-radius: 10px 20px; width: 100%"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-delete-modal">
                <AlertChangeTemplate
                    v-if="openConfirmModal"
                    :open="openConfirmModal"
                    @cancel="openConfirmModal = false"
                    @handleChangeTemplate="handleChangeTemplate"
                >
                </AlertChangeTemplate>
            </div>
            <div class="builddashboard">
                <DashboardBuilderModal
                    v-if="openLauncherModal"
                    :open="openLauncherModal"
                    @close="close"
                    :selected="selectedOption"
                    @selection-changed="updateSelectedOption"
                    :pageData="pageData"
                />
            </div>
            <div class="addmoduledetail">
                <AddDetailModal
                    v-if="openEditModal"
                    :open="openEditModal"
                    @close="closedetail"
                    :showSaveSetting="showSaveSetting"
                    :clickedProfileFunction="clickedProfileFunction"
                    :position="position"
                    :pageData="pageData"
                    :applicationFrame="applicationFrame"
                    :current_template="current_template"
                    :group="'LI'"
                />
            </div>
            <EducationFeedEditModal
                v-if="isEducation"
                @cancel="isEducation = false"
                :position="position"
                :applicationFrame="applicationFrame"
                :clickedProfileFunction="clickedProfileFunction"
                :pageData="pageData"
            />
        </div>
        <Loading v-if="loading" />
    </div>
</template>
<script>
    import { dashboardBuilder, memberProfile } from '@/util/apiRequests';
    import draggable from 'vuedraggable';
    import DashboardBuilderModal from '@/components/dashboard/DashboardBuilderModal.vue';
    import AlertChangeTemplate from './ChangeTemplateAlert.vue';
    import AddDetailModal from '../../components/company/member-profiles/AddGenericFunctionDetail.vue';
    import EducationFeedEditModal from './education_feed_edit_modal.vue';
    import Loading from '@/components/general/loading/loading.vue';
    export default {
        name: 'Build_MHH_FC_Dashboard',
        components: {
            draggable,
            DashboardBuilderModal,
            AlertChangeTemplate,
            AddDetailModal,
            EducationFeedEditModal,
            Loading,
        },
        props: {
            pageData: {
                type: Object,
            },
            current_template: {
                type: Number,
            },
            applicationFrame: {
                type: String,
            },
            dashboardId: {
                type: Number,
            },
        },
        data() {
            return {
                selectedOption: this.current_template,
                templateStyles: null,
                template: null,
                widgetsList: [],
                isHighlighted: false,
                inp: this.value,
                ref_span: null,
                openLauncherModal: false,
                openEditModal: false,
                openConfirmModal: false,
                templateData: {},
                templateDataList: [],
                height: '',
                width: '',
                showSaveSetting: false,
                showButtonsLI: [],
                showButtonsGSI: [],
                hoveredElementId: null,
                allWidgets: [],
                clickedProfileFunction: '',
                position: '',
                elementDragStartId: '',
                loading: true,
                isEducation: false,
            };
        },
        methods: {
            showButton(index, label) {
                if (label == 'LI') {
                    this.$set(this.showButtonsLI, index, true);
                } else {
                    this.$set(this.showButtonsGSI, index, true);
                }
            },
            async geturl(fileId) {
                const endpoint = dashboardBuilder.getUrls();
                const requestData = {
                    fileIds: [fileId],
                };
                let response = await this.$api.post(endpoint, requestData);
                return response?.data?.data[0].image;
            },
            hideButton(index, label) {
                if (label == 'LI') {
                    this.$set(this.showButtonsLI, index, false);
                } else {
                    this.$set(this.showButtonsGSI, index, false);
                }
            },
            addDetails(val, index) {
                let i = index + 1;
                this.position = i + '';
                this.clickedProfileFunction = val;
            },
            OpenEditModal(val, index) {
                let i = index + 1;
                this.position = i + '';
                this.clickedProfileFunction = val;
                if (val === 'Education') {
                    this.isEducation = true;
                } else {
                    this.openEditModal = true;
                }
            },
            closedetail() {
                this.openEditModal = false;
            },
            async deleteApplication(element, index) {
                let i = index + 1;
                this.position = i + '';
                this.clickedProfileFunction = element.function_name;

                const endpoint = memberProfile.getSpecificGenericFunction(
                    this.clickedProfileFunction,
                    this.position,
                    this.dashboardId
                );

                const functionResponse = await this.$api.get(endpoint);

                if (!functionResponse) {
                    throw 'e';
                }

                const info = functionResponse.data;

                if (info.info.length) {
                    try {
                        this.loading = true;
                        const endpoint = memberProfile.deleteGenericModule(
                            info.info[0].id,
                            this.clickedProfileFunction,
                            info.info[0].application
                        );
                        const saveFunctionResponse = await this.$api.delete(endpoint);
                        if (!saveFunctionResponse.data.success) {
                            throw saveFunctionResponse.data.error;
                        }
                        this.$toasted.success('Application deleted successfully !');
                    } catch (e) {
                        const errorMesage = e ? e : 'Failed to delete application !';
                        this.$toasted.error(errorMesage);
                    } finally {
                        this.loading = false;
                    }
                }
            },
            handleChangeTemplate() {
                this.openLauncherModal = true;
                this.openConfirmModal = false;
            },
            async getTemplateMetaData() {
                let metaData = await this.$api.get(dashboardBuilder.getTemplateMeta(this.selectedOption));
                let templateMeta = JSON.parse(metaData.data.templateMeta);
                let _template = templateMeta.templateItems;
                this.template = _template;
                Object.keys(this.template).forEach((group) => {
                    let groupElements = Object.keys(this.template[group]);
                    this.templateData[group] = {};
                    groupElements.forEach((groupEl) => {
                        this.templateData[group][groupEl] = undefined;
                    });
                });
                this.templateStyles = templateMeta.templateCommonStyles;
                this.$emit('captureValues', {
                    page4: { template: this.template, templateStyles: this.templateStyles },
                });
                return true;
            },
            manipulateWidgets(event, section, listName, operation, element, index) {
                if (operation === 'remove') {
                    // if (section == "GSI") {
                    //     // this.template[section][listName].style = `background-color: rgba(255, 255, 255, 0.85);`
                    //     // this.template[section][listName].style += `color: black;`
                    //     this.setColorFromColorScheme(listName);
                    //     this.template['GSI'][listName].isSensitive = 0;
                    // }
                    if (element.function_type != 'generic') {
                        if (element.function_type == 'core') {
                            let idx = this.widgetsList.findIndex((item) => item.function_type == '3rdParty');
                            if (idx != -1) {
                                this.widgetsList.splice(idx, 0, {
                                    ...element,
                                });
                            } else {
                                this.widgetsList.push(element);
                            }
                        } else {
                            this.widgetsList.push(element);
                        }

                        element.class = 'list-item removed';
                        this.template[section][listName].title = '';
                        this.template[section][listName].elements.pop();
                        this.templateData[section][listName] = undefined;
                        this.$set(this.template[section][listName], 'group', undefined);
                    } else {
                        if (['Mood Tracker', 'Education'].includes(element.function_name)) {
                            let idx = this.widgetsList.findIndex((item) => item.function_type == 'core');
                            if (idx != -1) {
                                this.widgetsList.splice(idx, 0, {
                                    ...element,
                                });
                            } else {
                                this.widgetsList.push(element);
                            }

                            element.class = 'list-item removed';
                            this.template[section][listName].title = '';
                            this.template[section][listName].elements.pop();
                            this.templateData[section][listName] = undefined;
                            this.$set(this.template[section][listName], 'group', undefined);
                        }

                        this.deleteApplication(element, index);

                        if (this.widgetsList.length >= 1) {
                            element.class = 'list-item removed';

                            this.template[section][listName].elements.pop();
                            this.template[section][listName].title = '';
                            this.templateData[section][listName] = undefined;
                            this.$set(this.template[section][listName], 'group', undefined);
                            return;
                        }
                    }
                } else {
                    let newlyAdded = operation === 'add' ? event.added.element : null;
                    if (!newlyAdded.function_type) {
                        delete newlyAdded.company_id;
                        delete newlyAdded.created_at;
                        delete newlyAdded.updated_at;
                    }
                    newlyAdded['function_type'] = !newlyAdded.function_type ? 'core' : newlyAdded.function_type;
                    if (operation === 'add') {
                        if (this.template[section][listName].elements.length > 1) {
                            this.template[section][listName].elements.splice(1);
                            newlyAdded.class = 'list-item removed';
                            if (newlyAdded.function_type == 'generic') {
                                this.widgetsList.unshift(newlyAdded);
                            } else if (newlyAdded.function_type == 'core') {
                                let idx = this.widgetsList.findIndex((item) => item.function_type == '3rdParty');
                                this.widgetsList.splice(idx, 0, {
                                    ...newlyAdded,
                                });
                            } else {
                                this.widgetsList.push(newlyAdded);
                            }
                        } else if (
                            newlyAdded.width != this.template[section][listName].width &&
                            newlyAdded.height != this.template[section][listName].height
                        ) {
                            this.template[section][listName].elements.splice(0);
                            newlyAdded.class = 'list-item removed';

                            if (newlyAdded.function_type == 'generic') {
                                this.widgetsList.unshift(newlyAdded);
                            } else if (newlyAdded.function_type == 'core') {
                                let idx = this.widgetsList.findIndex((item) => item.function_type == '3rdParty');
                                this.widgetsList.splice(idx, 0, {
                                    ...newlyAdded,
                                });
                            } else {
                                this.widgetsList.push(newlyAdded);
                            }
                        } else {
                            if (event.added.element.function_type == 'generic') {
                                if (!['Mood Tracker', 'Education'].includes(event.added.element.function_name)) {
                                    this.widgetsList.splice(this.elementDragStartId, 0, {
                                        ...event.added.element,
                                        id: event.added.element.id + 1,
                                    });
                                }
                            }

                            this.$set(this.template[section][listName], 'group', {
                                name: 'myGroup',
                                pull: false,
                                put: false,
                            });
                            this.templateData[section][listName] = newlyAdded;
                        }
                    }
                }
                this.$emit('captureValues', { page4: { templateData: this.templateData } });
            },
            async updateSelectedOption(option) {
                if (this.selectedOption != option) {
                    this.selectedOption = option;
                    this.$emit('selection-changed', this.selectedOption);
                    await this.getTemplateMetaData();
                    await this.getWidgets();
                    // this.templateStyles.bgImageUrl = this.pageData.page3.background.image;
                }
            },
            onDragStart(event) {
                this.elementDragStartId = event.item.id;
                let element = event.item._underlying_vm_;
                this.height = element.height;
                this.width = element.width;
            },
            onDragEnd() {
                this.height = null;
                this.width = null;
            },
            close() {
                this.openLauncherModal = false;
            },
            async getGenericWidgets() {
                const requestData = {
                    functionNames: this.pageData.page2.genericFunctions.map((el) => el.function_name),
                    application: this.applicationFrame,
                };
                const endpoint = dashboardBuilder.getWidgets();
                let response = await this.$api.post(endpoint, requestData);
                let res = response.data.data.map((el) => {
                    let data = {
                        ...el,
                        function_type: 'generic',
                    };
                    return data;
                });
                return res;
            },
            async getWidgets() {
                const response = await Promise.all([
                    this.getGenericWidgets(),
                    this.getCoreWidgets(),
                    this.get3rdPartyFunctionWidgets(),
                ]);
                this.widgetsList = this.widgetsList.concat(response[0]);
                this.widgetsList = this.widgetsList.concat(response[1]);
                this.widgetsList = this.widgetsList.concat(response[2]);
            },
            async getCoreWidgets() {
                const requestData = {
                    functionNames: this.pageData.page2.coreFunctions.map((el) => el.function_name + '2'),
                    application: this.applicationFrame,
                };
                const endpoint = dashboardBuilder.getWidgets();
                let response = await this.$api.post(endpoint, requestData);
                let res = response.data.data.map((el) => {
                    let data = {
                        ...el,
                        function_type: 'core',
                    };
                    return data;
                });
                return res;
            },
            async get3rdPartyFunctionWidgets() {
                if (this.pageData.page2.thirdPartyFunctions) {
                    const requestData = {
                        functionIds: this.pageData.page2.thirdPartyFunctions.map((el) => el.id),
                    };
                    const endpoint = memberProfile.getFunctionDetailsByIds();
                    let response = await this.$api.post(endpoint, requestData);
                    let thirdPartyWidgets = response.data.data.map((el) => {
                        let data = {
                            ...el,
                            height: 'a',
                            width: 'b',
                        };
                        return data;
                    });
                    return thirdPartyWidgets;
                } else {
                    return [];
                }
            },
            setColorFromColorScheme(item) {
                const { colorScheme } = this.pageData.page3;

                this.template['GSI'][item].style = `background-color: ${colorScheme.colors[0][0]};`;
                this.template['GSI'][item].style += `color: ${colorScheme.colors[0][1]}`;
            },
        },
        watch: {},

        async created() {
            window.scrollTo(0, 0);

            await this.getWidgets();
            const widgets = this.widgetsList.map((el) => el.id);
            this.allWidgets = this.widgetsList;
            const widgetsToRemoveIndexs = [];
            const widgetsToRemoveFunctionName = [];

            if (
                this.pageData.page4 &&
                this.pageData.page4.template &&
                this.pageData.page4.templateStyles &&
                this.pageData.page4.templateData
            ) {
                const { template, templateData, templateStyles } = this.pageData.page4;
                if (template) {
                    this.template = template;
                }
                if (templateStyles) {
                    this.templateStyles = templateStyles;
                }
                if (templateData) {
                    this.templateData = templateData;
                }

                // this.templateStyles.bgImageUrl = this.pageData.page3.background.image;
                Object.keys(this.template).forEach((parentEl) => {
                    if (!this.template[parentEl]) {
                        this.templateData[parentEl] = {};
                    }
                    Object.keys(this.template[parentEl]).forEach((childEl) => {
                        if (!this.templateData[parentEl][childEl]) {
                            this.templateData[parentEl][childEl] = {};
                        }
                        if (this.templateData[parentEl][childEl].function_type != 'generic') {
                            if (this.templateData[parentEl][childEl]) {
                                let i = widgets.indexOf(this.templateData[parentEl][childEl].id);
                                if (i > -1) {
                                    this.templateData[parentEl][childEl].image = this.widgetsList[i].image;
                                    this.$set(this.template[parentEl][childEl], 'elements', [
                                        this.templateData[parentEl][childEl],
                                    ]);
                                    widgetsToRemoveIndexs.push(this.templateData[parentEl][childEl].id);
                                    this.$set(this.template[parentEl][childEl], 'group', {
                                        name: 'myGroup',
                                        pull: false,
                                        put: false,
                                    });
                                } else {
                                    this.$set(this.template[parentEl][childEl], 'elements', []);
                                    this.templateData[parentEl][childEl] = undefined;
                                    this.$set(this.template[parentEl][childEl], 'group', {
                                        name: 'myGroup',
                                        pull: false,
                                        put: true,
                                    });
                                }
                            } else {
                                this.$set(this.template[parentEl][childEl], 'elements', []);
                                this.$set(this.template[parentEl][childEl], 'group', {
                                    name: 'myGroup',
                                    pull: false,
                                    put: true,
                                });
                            }
                        }
                        if (
                            ['Mood Tracker', 'Education'].includes(this.templateData[parentEl][childEl]?.function_name)
                        ) {
                            if (this.templateData[parentEl][childEl]) {
                                widgetsToRemoveFunctionName.push(this.templateData[parentEl][childEl]?.function_name);
                            }
                        }
                    });
                });

                widgetsToRemoveIndexs.forEach((id) => {
                    const i = this.widgetsList.findIndex((el) => el.id == id);
                    this.widgetsList.splice(i, 1);
                });

                widgetsToRemoveFunctionName.forEach((name) => {
                    const i = this.widgetsList.findIndex((el) => el.function_name == name);
                    this.widgetsList.splice(i, 1);
                });

                Object.keys(this.template).forEach((group) => {
                    Object.keys(this.template[group]).forEach(async (item) => {
                        if (this.template[group][item].elements.length) {
                            let elements = this.template[group][item].elements[0];
                            if (elements.image_id) {
                                elements.image = await this.geturl(elements.image_id);
                            } else if (elements.widget_png) {
                                elements.image = await this.geturl(elements.widget_png);
                            } else if (elements.fileId) {
                                elements.image = await this.geturl(elements.fileId);
                            }
                        }
                    });
                });

                Object.keys(this.template).forEach((group) => {
                    if (group === 'GSI') {
                        Object.keys(this.template[group]).forEach((gsi) => {
                            const gsiStyle = this.template[group][gsi].style;
                            const { colorScheme } = this.pageData.page3;

                            if (gsiStyle.includes('background-color')) {
                                this.template[group][gsi].style = gsiStyle.replace(
                                    /background-color:[^;]*/,
                                    `background-color:${this.pageData.page3.colorScheme.colors[0][0]}`
                                );
                            } else {
                                this.template[group][
                                    gsi
                                ].style += `background-color:${this.pageData.page3.colorScheme.colors[0][0]};`;
                            }

                            const styleArray = this.template[group][gsi].style.split(';');
                            let colorText = 0;

                            styleArray.forEach((style, index, array) => {
                                const propertyValue = style.split(':');
                                if (propertyValue[0].trim() === 'color') {
                                    colorText++;
                                    array[index] = `color:${colorScheme.colors[0][1]}`;
                                }
                            });

                            // Join the modified style array back into a string
                            this.template[group][gsi].style = styleArray.join(';');

                            if (!colorText) {
                                this.template[group][gsi].style += `color:${colorScheme.colors[0][1]};`;
                                colorText = 0;
                            }

                            const elements = this.template[group][gsi].elements[0];

                            if (elements != undefined && elements.function_type == 'generic') {
                                this.template[group][gsi].isSensitive = this.template[group][gsi]?.isSensitive ?? 0;
                            }
                        });
                    }
                });
            } else {
                await this.getTemplateMetaData();
                // this.templateStyles.bgImageUrl = this.pageData.page3.background.image;
                Object.keys(this.template).forEach((group) => {
                    if (group === 'GSI') {
                        Object.keys(this.template[group]).forEach((gsi) => {
                            this.setColorFromColorScheme(gsi);

                            const elements = this.template[group][gsi].elements[0];

                            if (elements != undefined && elements.function_type == 'generic') {
                                this.template[group][gsi].isSensitive = this.template[group][gsi]?.isSensitive ?? 0;
                            }
                        });
                    }
                });
            }

            this.loading = false;
        },
    };
</script>
<style scoped>
    .hover-button {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, -180%);
    }

    .dashboard-builder-template {
        background: rgba(255, 255, 255, 0.9);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        max-width: 1500px;
        gap: 2rem;
        padding: 2rem;
    }

    .dashboard-builder-template > div {
        border-radius: 1rem !important;
        box-sizing: border-box;
    }

    .widget-list {
        width: 25%;
        min-width: 18rem;
        border: 1px solid rgb(193, 187, 187);
    }

    .widget-list-header {
        /* padding: 2rem; */
        background-color: rgb(214, 230, 235);
        font-weight: 700;
        border-radius: 1rem 1rem 0 0;
        padding: 1rem;
        /* display: none!important; */
    }

    .widget {
        width: 11rem;
        height: 10rem;
        background-color: white;
        box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.15);
        border-radius: 2.5rem;
        display: flex;
    }

    .generic-widget {
        background-color: #fff;
        box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.15);
        border-radius: 30px;
        height: 8rem;
        width: 8rem;
        display: flex;
        align-items: center;
    }

    .generic-widget > img {
        height: 70%;
    }

    .core-widget {
        width: calc(var(--mini-display-width) / 6.3);
        box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.15);
        background: rgba(81, 85, 195, 0.95);
        padding: 10px;
        border-radius: 0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5rem;
        min-width: 7rem;
    }

    .core-widget > img {
        height: 90%;
    }

    .widgets-list .widget-item {
        margin: 10px;
        padding: 10px;
        cursor: pointer;
        font-size: 18px;
        border-radius: 5px;
        background: #27a2d3;
        display: inline-block;
    }

    .draggable-list {
        height: 100%;
        width: 100%;
    }

    .highlighted-section {
        border: 4px dotted #1fcf25;
    }

    .list-item {
        height: 100%;
        width: 100%;
        /* background: lightblue; */
    }

    .action-buttons-mhh {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        top: 0;
        background-color: rgb(89, 90, 194, 0.85);
        border-radius: 0.5rem;
    }

    .background {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e8f0fd;
        border: 1px solid black;
    }

    .widget-title {
        height: 20px;
        width: 80%;
        margin-top: 0.5rem;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        text-transform: capitalize;
        color: rgb(81, 85, 195);
    }

    .icon-container {
        color: #271e4a;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-weight: 600;
    }

    .side-container {
        display: flex;
        gap: 30px;
        flex-direction: column;
        justify-content: center;
        margin-left: 15px;
    }

    .side-container-item {
        height: 23%;
        background-color: white;
        border-radius: 35px;
        box-shadow: 5px 10px 5px 5px rgba(0, 0, 0, 0.07), 0px 6px 4px 0px rgba(255, 255, 255, 0.07) inset;
    }
    .core-class {
        border-radius: 15px;
        padding: 10px;
    }
    .li-items-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 35px;
        height: 100%;
    }
</style>
