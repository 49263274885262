<template>
    <div class="rounded-5 w-100 my-5">
        <div class="bg-white w-100 rounded-4 border">
            <h1
                class="px-5 py-3 page-heading"
                style="background: #f1f7ff; font-weight: bold; border-radius: 14px 14px 0 0"
            >
                Select Module For Application
            </h1>
            <div class="d-flex flex-wrap p-4" style="gap: 0.5em">
                <ProfileFunctionCard
                    v-for="coreFunction in coreFunctionList"
                    :key="coreFunction.id"
                    :profileFunction="coreFunction"
                    @onToggle="coreFunctionToggle($event)"
                />
            </div>
            <div class="d-flex justify-content-center" v-if="coreFunctionList.length">
                <hr style="width: 95%" />
            </div>
            <div class="d-grid p-4" v-if="genericModuleList.length">
                <div class="d-flex justify-content-between align-items-end">
                    <div>
                        <span class="app-launcher-label">Generic Modules</span>
                    </div>
                </div>
                <div class="d-flex flex-wrap py-4" style="gap: 0.5em">
                    <ProfileFunctionCard
                        v-for="genericFunction in genericModuleList"
                        :key="genericFunction.id"
                        :profileFunction="genericFunction"
                        @onToggle="genericFunctionToggle($event)"
                    />
                </div>
            </div>
            <div class="d-flex justify-content-center" v-if="genericModuleList.length">
                <hr style="width: 95%" />
            </div>
            <div class="d-grid p-4">
                <div class="d-flex justify-content-between align-items-end">
                    <div>
                        <label for="app-launcher" class="app-launcher-label">3rd Party app launcher</label>
                        <Toggle
                            id="app-launcher"
                            class="toggle-switch"
                            labelClass="switch"
                            v-model="openLauncherModal"
                            spanClass="slider"
                        />
                    </div>
                    <LauncherModal
                        v-if="openLauncherModal"
                        :open="openLauncherModal"
                        @close="close"
                        :coreFunctionList="thirdPartyApps"
                        @onSaveThirdPartyApplication="onSaveThirdPartyApplication"
                        @onDeleteThirdParty="onDeleteThirdParty"
                        :showSaveSetting="showSaveSetting"
                        :applicationFrame="applicationFrame"
                    />
                </div>
                <div class="d-flex flex-wrap py-4" style="gap: 0.5em">
                    <ProfileFunctionCard
                        v-for="thirdPartyApp in thirdPartyApps"
                        :key="thirdPartyApp.id"
                        :profileFunction="thirdPartyApp"
                        :coreFunctionList="thirdPartyApps"
                        @onToggle="thirdPartyFunctionToggle($event)"
                    />
                </div>
            </div>
            <div v-if="applicationFrame === 'mobex-health-hub'" class="d-grid p-4">
                <div class="d-flex justify-content-center">
                    <hr style="width: 95%" />
                </div>
                <div class="d-flex justify-content-between flex-column">
                    <div>
                        <label for="bidirectionalMessaging" class="app-launcher-label"
                            >Allow Bidirectional Message</label
                        >
                        <Toggle
                            id="bidirectionalMessaging"
                            class="toggle-switch"
                            labelClass="switch"
                            spanClass="slider"
                            v-model="bidirectionalMessaging"
                            @input="handleBiDirectionalMessaging"
                        />
                    </div>
                    <div v-if="bidirectionalMessaging" class="w-75">
                        <label for="disclaimer" class="my-2">Disclaimer message</label>
                        <TextArea
                            id="disclaimer"
                            class="my-2"
                            type="text"
                            name="disclaimer"
                            label=""
                            :placeholder="'Enter disclaimer message'"
                            v-model="bidirectionalDisclaimer"
                            @input="handleBiDirectionalDisclaimer"
                        />
                    </div>
                </div>
            </div>
            <div v-if="applicationFrame === 'kiosk'">
                <div class="d-flex justify-content-center">
                    <hr style="width: 95%" />
                </div>
                <div class="d-grid p-4">
                    <div class="d-flex justify-content-between align-items-end">
                        <div>
                            <label for="enableQR" class="app-launcher-label">App Download Button</label>
                            <Toggle
                                id="enableQR"
                                class="toggle-switch"
                                labelClass="switch"
                                spanClass="slider"
                                v-model="enableQR"
                                @input="enableQRButtonToggle"
                            />
                            <div v-if="enableQR" class="p-1">
                                <Toggle
                                    id="showQR"
                                    class="toggle-switch"
                                    labelClass="switch"
                                    spanClass="slider"
                                    v-model="showQR"
                                    @input="showQRButtonToggle"
                                />
                                <label for="showQR" class="p-1" style="color: rgb(160, 160, 160); font-weight: 600"
                                    >Show QR on screen saver</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="applicationFrame === 'kiosk' || applicationFrame === 'star-kid'">
                <div class="d-flex justify-content-center">
                    <hr style="width: 95%" />
                </div>
                <div class="d-grid p-4">
                    <div class="d-flex justify-content-between align-items-end">
                        <div>
                            <label
                                for="isMaster"
                                class="app-launcher-label"
                                :style="{ cursor: disableMasterButton || isMasterExist ? 'not-allowed' : '' }"
                                >Master Dashboard</label
                            >
                            <Toggle
                                id="isMaster"
                                class="toggle-switch"
                                labelClass="switch"
                                v-model="isMaster"
                                spanClass="slider"
                                @input="handleMasterDashboard"
                                :disabled="!!(disableMasterButton || isMasterExist)"
                            />
                        </div>
                    </div>
                    <span v-if="isMasterExist" class="p-1" style="color: rgb(160, 160, 160); font-weight: 600"
                        >Master Dashboard already exist for selected application</span
                    >
                    <span
                        v-else-if="disableMasterButton || isMasterExist"
                        class="p-1"
                        style="color: rgb(160, 160, 160); font-weight: 600"
                        >Master dashboard can only be set while creating a dashboard</span
                    >
                </div>
            </div>
            <span class="m-3" style="color: red; font-weight: bold">{{ errors.modules }}</span>
            <div class="third-party-app-delete-modal">
                <AlertDeleteThirdPartyApp
                    v-if="showThirdPartyDeleteAlert"
                    :open="showThirdPartyDeleteAlert"
                    @cancel="
                        showThirdPartyDeleteAlert = false;
                        openLauncherModal = true;
                    "
                    @handleDelete="handleThirdPartyDelete"
                >
                </AlertDeleteThirdPartyApp>
            </div>
        </div>
        <Loading v-if="loading" />
    </div>
</template>
<script>
    import ProfileFunctionCard from '../../components/company/member-profiles/ProfileFunctionCard.vue';
    import LauncherModal from '../../components/company/member-profiles/LauncherModal.vue';
    import AlertDeleteThirdPartyApp from '../../components/company/member-profiles/DeleteThirdPartyAlert.vue';
    import Loading from '@/components/general/loading/loading.vue';
    import { memberProfile, dashboardBuilder } from '@/util/apiRequests';

    export default {
        name: 'SelectModules',
        props: {
            errors: {
                type: Object,
            },
            pageData: {
                type: Object,
            },
            applicationFrame: {
                type: String,
            },
            current_template: {
                type: Number,
            },
        },
        components: {
            ProfileFunctionCard,
            AlertDeleteThirdPartyApp,
            LauncherModal,
            Loading,
        },
        computed: {
            disableMasterButton() {
                return this.$route?.query?.id;
            },
        },
        data() {
            return {
                coreFunctionList: [],
                genericModuleList: [],
                openLauncherModal: false,
                showSaveSetting: false,
                thirdPartyApps: [],
                showThirdPartyDeleteAlert: false,
                thirdPartyFunctionIdToDelete: null,
                enableQR: false,
                showQR: false,
                loading: true,
                isMaster: false,
                isMasterExist: false,
                bidirectionalMessaging: false,
                bidirectionalDisclaimer: '',
            };
        },
        methods: {
            async getCoreFunctions() {
                try {
                    const endpoint = memberProfile.getFunction('core', this.applicationFrame);
                    const fetchFunctionResponse = await this.$api.get(endpoint);
                    if (!fetchFunctionResponse.data.success) {
                        throw fetchFunctionResponse.data.error;
                    }
                    let { data } = fetchFunctionResponse.data;
                    this.coreFunctionList = data.map((el) => {
                        delete el.created_at;
                        delete el.updated_at;
                        delete el.deleted;
                        return el;
                    });
                    this.coreFunctionList.forEach((el) => (el.active = el.by_default_selected ? 1 : 0));

                    if (this.applicationFrame == 'mobex-health-hub') {
                        let MHH_new_template_modules = [
                            'Scheduling',
                            'Care Team',
                            'Games',
                            'Medication',
                            'Web Links',
                            'Content & Education',
                            "Let's Connect",
                        ];
                        if (this.current_template == 1) {
                            this.coreFunctionList = this.coreFunctionList.filter(
                                (item) => item.function_name != 'Games'
                            );
                        } else {
                            this.coreFunctionList = this.coreFunctionList.filter((item) =>
                                MHH_new_template_modules.includes(item.function_name)
                            );
                        }
                    }
                    // Emiting default selected modules
                    return true;
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to get tablet functions !';
                    this.$toasted.error(errorMesage);
                }
            },
            async getGenericWidgets() {
                try {
                    const endpoint = memberProfile.getFunction('generic', this.applicationFrame);
                    const fetchFunctionResponse = await this.$api.get(endpoint);
                    if (!fetchFunctionResponse.data.success) {
                        throw fetchFunctionResponse.data.error;
                    }
                    let { data } = fetchFunctionResponse.data;
                    this.genericModuleList = data.map((el) => {
                        delete el.created_at;
                        delete el.updated_at;
                        delete el.deleted;
                        return el;
                    });
                    this.genericModuleList.forEach((el) => (el.active = el.by_default_selected ? 1 : 0));

                    this.genericModuleList.forEach((item) => {
                        if (item.function_name == 'Video Link') {
                            item.name = 'Video';
                        } else if (item.function_name == 'PDF Link') {
                            item.name = 'PDF';
                        } else if (item.function_name == 'Dashboard Page') {
                            item.name = '3 Layer Module';
                        } else {
                            item.name = item.function_name;
                        }
                    });

                    if (this.applicationFrame == 'mobex-health-hub') {
                        if (this.current_template == 1 || this.current_template == 4) {
                            this.genericModuleList = this.genericModuleList.filter(
                                (el) => el.function_name != 'Call Button'
                            );
                        }
                    }
                    return true;
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to get tablet functions !';
                    this.$toasted.error(errorMesage);
                }
            },
            coreFunctionToggle(event) {
                this.coreFunctionList = this.coreFunctionList.map((el) => {
                    if (event.profileFunction.id === el.id) {
                        el.active = event.value ? 1 : 0;
                    }
                    return el;
                });
                let _coreFunctionList = this.coreFunctionList.filter((el) => el.active);
                this.$emit('captureValues', { page2: { coreFunctions: _coreFunctionList } });
            },
            genericFunctionToggle(event) {
                this.genericModuleList = this.genericModuleList.map((el) => {
                    if (event.profileFunction.id === el.id) {
                        el.active = event.value ? 1 : 0;
                    }
                    return el;
                });
                let _genericFunctionList = this.genericModuleList.filter((el) => el.active);
                this.$emit('captureValues', { page2: { genericFunctions: _genericFunctionList } });
            },
            onSaveThirdPartyApplication(selectedThirdPartyApplications) {
                this.thirdPartyApps = selectedThirdPartyApplications
                    .filter((el) => el.active == 1)
                    .map((el) => {
                        delete el.created_at;
                        delete el.deleted;
                        delete el.updated_at;
                        return el;
                    });
                this.thirdPartyApps;
                this.$emit('captureValues', { page2: { thirdPartyFunctions: this.thirdPartyApps } });
            },
            thirdPartyFunctionToggle(event) {
                let index = this.thirdPartyApps.findIndex((el) => el.id == event.profileFunction.id);
                this.thirdPartyApps.splice(index, 1);
                this.$emit('captureValues', { page2: { thirdPartyFunctions: this.thirdPartyApps } });
            },
            onDeleteThirdParty(thirdPartyFunctionId) {
                this.openLauncherModal = false;
                this.showThirdPartyDeleteAlert = true;
                this.thirdPartyFunctionIdToDelete = thirdPartyFunctionId;
            },
            close() {
                this.openLauncherModal = false;
            },
            async handleThirdPartyDelete() {
                try {
                    this.showThirdPartyDeleteAlert = false;
                    this.showLoader = true;

                    // HTTP call for delete third party application
                    const endpoint = memberProfile.deleteFunction(this.thirdPartyFunctionIdToDelete);
                    const deleteThirdPartyAppResponse = await this.$api.delete(endpoint);
                    if (!deleteThirdPartyAppResponse.data.success) {
                        throw deleteThirdPartyAppResponse.data.Message;
                    }

                    this.$toasted.success('Application Deleted successfully !');
                } catch (e) {
                    const errorMessage = e ? e : 'Failed to delete application !';
                    this.$toasted.error(errorMessage);
                } finally {
                    this.showLoader = false;
                    this.openLauncherModal = true;
                }
            },
            enableQRButtonToggle() {
                this.showQR = false;
                this.$emit('captureValues', {
                    page2: { enableQR: this.enableQR === true ? 1 : 0, showQR: 0 },
                });
            },
            showQRButtonToggle() {
                this.$emit('captureValues', {
                    page2: { showQR: this.showQR === true ? 1 : 0 },
                });
            },
            async checkMasterDashboardAvailable() {
                const endpoint = dashboardBuilder.isMasterExist();
                const response = await this.$api.post(endpoint, { application: this.applicationFrame });
                this.isMasterExist = response?.data?.masterExists;
            },
            handleMasterDashboard(value) {
                this.$emit('captureValues', { page2: { isMaster: value ? 1 : 0 } });
            },
            handleBiDirectionalMessaging() {
                if (this.bidirectionalMessaging) {
                    this.bidirectionalDisclaimer =
                        'Messages sent to Provider will be responded to within 2 business days. For urgent assistance, please contact provider directly by phone';
                }
                this.$emit('captureValues', {
                    page2: {
                        bidirectional_messaging: this.bidirectionalMessaging === true ? 1 : 0,
                        bidirectionalDisclaimer: this.bidirectionalMessaging ? this.bidirectionalDisclaimer : '',
                    },
                });
            },
            handleBiDirectionalDisclaimer() {
                this.$emit('captureValues', {
                    page2: { bidirectionalDisclaimer: this.bidirectionalDisclaimer },
                });
            },
        },
        async created() {
            window.scrollTo(0, 0);
            this.enableQR = this.pageData?.page2?.enableQR === 1 ? true : false;
            this.showQR = this.pageData?.page2?.showQR === 1 ? true : false;
            this.bidirectionalMessaging = this.pageData?.page2?.bidirectional_messaging;
            this.bidirectionalDisclaimer = this.pageData?.page2?.bidirectionalDisclaimer;

            await this.getCoreFunctions();
            await this.getGenericWidgets();

            if (!this.$route.query.id && ['kiosk', 'star-kid'].includes(this.applicationFrame)) {
                await this.checkMasterDashboardAvailable();
            } else {
                this.isMaster = this.pageData?.page2?.isMaster;
            }

            if (this.pageData.page2 && this.pageData.page2.coreFunctions && this.pageData.page2.genericFunctions) {
                if (this.pageData.page2.thirdPartyFunctions) {
                    this.thirdPartyApps = this.pageData.page2.thirdPartyFunctions
                        ? this.pageData.page2.thirdPartyFunctions
                        : [];
                }
                if (this.pageData.page2.coreFunctions) {
                    let coreFunctionIds = this.pageData.page2.coreFunctions.map((el) => el.id);
                    this.coreFunctionList = this.coreFunctionList.map((el) => {
                        if (coreFunctionIds.includes(el.id)) {
                            el.active = 1;
                        } else {
                            el.active = 0;
                        }
                        return el;
                    });
                }
                if (this.pageData.page2.genericFunctions) {
                    if (this.applicationFrame == 'mobex-health-hub') {
                        if (this.current_template == 1 || this.current_template == 4) {
                            this.pageData.page2.genericFunctions = this.pageData.page2.genericFunctions.filter(
                                (el) => el.function_name != 'Call Button'
                            );
                        }
                    }

                    let genericFunctionIds = this.pageData.page2.genericFunctions.map((el) => el.id);
                    this.genericModuleList = this.genericModuleList.map((el) => {
                        if (genericFunctionIds.includes(el.id)) {
                            el.active = 1;
                        } else {
                            el.active = 0;
                        }
                        return el;
                    });
                }
            } else {
                this.$emit('captureValues', {
                    page2: { coreFunctions: this.coreFunctionList.filter((el) => el.by_default_selected) },
                });
                this.$emit('captureValues', {
                    page2: { genericFunctions: this.genericModuleList.filter((el) => el.by_default_selected) },
                });
            }
            this.loading = false;
        },
    };
</script>
